import React from "react";

import styles from "./TitlePart.module.css";

const TitlePart = ({ top, title, invert }) => {
  return (
    <div className={styles.titleWrapp}>
      <h4>{top}</h4>
      <h2 style={{ color: invert ? "white" : "#011150" }} className="nunito">
        {title}
      </h2>
      <div className={styles.line}></div>
    </div>
  );
};

export default TitlePart;
