import React from "react";

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8"
    >
      <path
        fill="#fff"
        d="M6.607 5.173L11.62.223l1.432 1.414L6.607 8 .16 1.637 1.592.223l5.015 4.95z"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;
