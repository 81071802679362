import React from "react";

function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <g clipPath="url(#clip0_204_197)">
        <path
          fill="#fff"
          d="M11.528 6.729h.584a.583.583 0 01.583.583v5.834a.583.583 0 01-.583.583H2.778a.583.583 0 01-.583-.583V7.312a.583.583 0 01.583-.583h.584v-.583a4.083 4.083 0 118.166 0v.583zm-1.166 0v-.583a2.917 2.917 0 00-5.834 0v.583h5.834zm-3.5 2.333v2.334h1.166V9.062H6.862z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_204_197">
          <path
            fill="#fff"
            d="M0 0H14V14H0z"
            transform="translate(.445 .896)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LockIcon;
