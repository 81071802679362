import React from "react";

function CalculatorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="31"
      fill="none"
      viewBox="0 0 24 31"
    >
      <path
        fill="#fff"
        d="M4 2.71h16c.265 0 .52.132.707.368.188.236.293.556.293.89v22.645c0 .333-.105.653-.293.89-.187.235-.442.368-.707.368H4c-.265 0-.52-.133-.707-.369a1.44 1.44 0 01-.293-.89V3.969c0-.334.105-.654.293-.89.187-.236.442-.369.707-.369zm1 2.516v20.129h14V5.225H5zm2 2.516h10v5.032H7V7.742zm0 7.548h2v2.516H7V15.29zm0 5.032h2v2.516H7v-2.516zm4-5.032h2v2.516h-2V15.29zm0 5.032h2v2.516h-2v-2.516zm4-5.032h2v7.548h-2V15.29z"
      ></path>
    </svg>
  );
}

export default CalculatorIcon;
