import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

import LandingWrapper from "../../layouts/LandingWrapper";
import { toast } from 'react-toastify'
import {
  ArrowNextIcon,
  CheckIcon,
  MailIcon,
  PasswordIcon,
  ReferalIcon,
  FacebookIcon,
  AppleIcon,
  GoogleIcon,
  LockIcon,
} from "../../assets/icons";
import axios from "axios";
const Login = () => {

  let location = useLocation()

  let path = location.pathname;
  console.log(path)

  if (path == "/register") {

  }

  const [activeTab, setActiveTab] = useState("login");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [securityPin, setSecurityPin] = useState("");


  const { id } = useParams();


  React.useEffect(() => {
    if (path == "/register") {
      setActiveTab("signUp")
    }
  }, [path])

  let loginAPI = 'https://yield-jet.sentrynode.io/auth/login'
  let signupAPI = 'https://yield-jet.sentrynode.io/auth/signup'

  const getUserBalance = async () => {
    let token = localStorage.getItem('token')
    if (!token) return;
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data, status } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          'clientid': process.env.REACT_APP_CLIENT_ID,
          'x-access-token': token
        }
      });
      if (status == 200) {
        // window.location.href = '/dashboard/home'
      }

    } catch (e) {
    }
  }
  React.useEffect(() => {
    getUserBalance()
    if (id) {
      setReferralCode(id)
      setActiveTab('signUp')
    }
  }, [])

  let loginHandler = async () => {

    if (!email.includes("@")) {
      toast.error("Invalid email", {
        toastId: 'login-error',
        position: 'top-center'
      })
      return;
    }

    if (password.length < 4) {
      toast.error("Password must be at least 6 characters", {
        toastId: 'login-error',
        position: 'top-center'
      })
      return;
    }
    try {
      let { data } = await axios.post(loginAPI, {
        email: email,
        password: password
      }, {
        headers: {
          'Content-Type': 'application/json',
          'clientid': process.env.REACT_APP_CLIENT_ID
        }
      })
      if (data.message == 'Login successful') {

        //change to http only cookie
        Cookies.set('token', data.token)
        toast.success("Login successful", {
          toastId: 'login-success',
          position: 'top-center'
        })
        toast.loading("Redirecting to dashboard", {
          toastId: 'login-loading',
          position: 'top-center'

        })

        setTimeout(() => {
          window.location.href = '/dashboard/home'
        }, 2000)

      } else if (data.message == 'User not found') {
        alert("Invalid credentials")
      }
    } catch (e) {
      if (e.response.data.message == 'User not found') {
        toast.error("Invalid credentials", {
          toastId: 'login-error',
          position: 'top-center'
        })
      }

      if (e.response.data.message == 'Invalid credentials') {
        toast.error("Invalid credentials", {
          toastId: 'login-error',
          position: 'top-center'
        })
      }
    }
  };


  const signUpHandler = async () => {



    if (!email.includes("@") || !email.includes(".") || email.length < 5) {
      toast.error("Invalid email", {
        toastId: 'login-error',
        position: 'top-center'
      })
      return;
    }

    if (password.length < 4) {
      toast.error("Password must be at least 6 characters", {
        toastId: 'login-error',
        position: 'top-center'
      })
      return;
    }

    if (!username || !firstName || !lastName) {
      toast.error("Please fill all the fields", {
        toastId: 'login-error',
        position: 'top-center'
      })
    }
    if (securityPin.length < 4) {
      toast.error("Security pin must be at least 4 characters", {
        toastId: 'login-error',
        position: 'top-center'
      })
      return;
    }



    if (!email || !password || !username || !firstName || !lastName || !securityPin) {
      alert("Please fill all the fields")
      return;
    }
    try {
      let { data } = await axios.post(signupAPI, {
        email: email,
        password: password,
        username: username,
        firstName: firstName,
        lastName: lastName,
        referralCode: referralCode,
        securityPin: securityPin
      }, {
        headers: {
          'Content-Type': 'application/json',
          'clientid': process.env.REACT_APP_CLIENT_ID
        }
      })
      if (data.message == 'User created successfully') {
        toast.success("User created successfully", {
          position: 'top-center'

        })
        loginHandler();
      } else if (data.message == 'User already exists') {
        alert("User already exists")
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: 'login-error',
        position: 'top-center'
      })
    }
  }
  return (
    <LandingWrapper>
      <div className="login-page flex">
        <div className="wrap wrapWidthHeader flex items-center justify-center">
          <div className="main-box flex bg-themeColor2">
            <div className="box-left-side bg-blueDark flex flex-col">
              <div className="title-box flex flex-col">
                <div className="sec-title">Welcome back</div>
                <div className="sec-desc">
                  Please Enter your email and password
                </div>
              </div>
              <div className="selection-tabs flex items-center">
                <div className="tab-box flex items-center bg-themeColor1">
                  <button
                    className={`btn button ${activeTab === "login" ? "active" : ""
                      }`}
                    onClick={(e) => setActiveTab("login")}
                  >
                    Login
                  </button>
                  <button
                    className={`btn button ${activeTab === "signUp" ? "active" : ""
                      }`}
                    onClick={(e) => setActiveTab("signUp")}
                  >
                    SignUp
                  </button>
                </div>
              </div>
              <div className="form flex flex-col w-full">
                <div className="input-field flex items-center bg-themeColor1">
                  <div className="input-icon flex items-center justify-center">
                    <MailIcon />
                  </div>
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="txt cleanbtn w-full"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-field flex items-center bg-themeColor1">
                  <div className="input-icon flex items-center justify-center">
                    <PasswordIcon />
                  </div>
                  <input
                    type="password"
                    placeholder="Password"
                    className="txt cleanbtn w-full"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {/* sign up  */}
                {activeTab == 'signUp' && <>
                  <div className="input-field flex items-center bg-themeColor1">
                    <div className="input-icon flex items-center justify-center">
                      <ReferalIcon />
                    </div>
                    <input
                      type="text"
                      placeholder="Referral code (Optional)"
                      value={referralCode}
                      className="txt cleanbtn w-full"
                    />

                  </div>


                  <div className="input-field flex items-center bg-themeColor1">
                    <div className="input-icon flex items-center justify-center">
                      <ReferalIcon />
                    </div>
                    <input
                      type="text"
                      placeholder="username"
                      onChange={(e) => setUsername(e.target.value)}
                      className="txt cleanbtn w-full"
                    />
                  </div>

                  <div className="input-field flex items-center bg-themeColor1">
                    <div className="input-icon flex items-center justify-center">
                      <ReferalIcon />
                    </div>
                    <input
                      type="text"
                      placeholder="first name"
                      onChange={(e) => setFirstName(e.target.value)}
                      className="txt cleanbtn w-full"
                    />
                  </div>

                  <div className="input-field flex items-center bg-themeColor1">
                    <div className="input-icon flex items-center justify-center">
                      <ReferalIcon />
                    </div>
                    <input
                      type="text"
                      placeholder="last name"
                      onChange={(e) => setLastName(e.target.value)}
                      className="txt cleanbtn w-full"
                    />
                  </div>

                  <div className="input-field flex items-center bg-themeColor1">
                    <div className="input-icon flex items-center justify-center">
                      <ReferalIcon />
                    </div>
                    <input
                      type="number"
                      placeholder="security pin"
                      onChange={(e) => setSecurityPin(e.target.value)}
                      className="txt cleanbtn w-full"
                    />
                  </div>

                </>}

                <button
                  onClick={activeTab == "login" ? loginHandler : signUpHandler}
                  className="btn-continue button flex items-center justify-center">
                  <div className="btn-lbl flex-1 text-center">{activeTab == "login" ? "Login" : "Sign Up"}</div>
                  <div className="btn-icon flex items-center justify-center">
                    <ArrowNextIcon />
                  </div>
                </button>


              </div>
              {activeTab == "login" && <div className="forgot-pass flex items-center justify-right ">
                <div
                  onClick={(
                    () => {
                      window.location.href = '/reset/password'
                    }
                  )}
                  className="forgot-pass-lbl">Forgot Password? Reset</div>
              </div>}
              {/* <div className="socials-login flex flex-col">
                <div className="social-tag flex items-center justify-center relative">
                  <div className="social-lbl absolute bg-blueDark">
                    Or Continue with
                  </div>
                </div>
                <div className="social-icons flex items-center justify-center">
                  <div className="icon flex items-center justify-center bg-themeColor1">
                    <FacebookIcon />
                  </div>
                  <div className="icon flex items-center justify-center bg-themeColor1">
                    <AppleIcon />
                  </div>
                  <div className="icon flex items-center justify-center bg-themeColor1">
                    <GoogleIcon />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="box-right-side flex flex-col">
              <div className="qr-code flex items-center justify-center flex-col">
                <img src="/images/Qrcode.svg" className="code-img" />
                <div className="qr-desc">Login With QR code</div>
                <div className="qr-app">
                  Scan QR code with <span className="text-themeColor">app</span>
                </div>
              </div>
              <div className="url-lock-sec flex flex-col items-center justify-center">
                <div className="url-box flex items-center bg-themeColor1">
                  <div className="lock-icon flex items-center justify-center">
                    <LockIcon />
                  </div>
                  <div className="url">
                    <span className="text-themeColor"></span>
                    {process.env.REACT_APP_WEBSITE}
                  </div>
                </div>
                <div className="slogan">
                  Trade anything anywhere
                  <br /> with {process.env.REACT_APP_NAME}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  );
};

const ResetPass = () => {
  const [codeStage, setCodeStage] = useState(false)

  const [activeTab, setActiveTab] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");

  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [securityPin, setSecurityPin] = useState("");


  const { id } = useParams();


  let loginAPI = 'https://yield-jet.sentrynode.io/auth/login'
  let signupAPI = 'https://yield-jet.sentrynode.io/auth/signup'

  const getUserBalance = async () => {

    let token = localStorage.getItem('token')
    if (!token) return;
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data, status } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          'clientid': process.env.REACT_APP_CLIENT_ID,
          'x-access-token': token
        }
      });
      if (status == 200) {
        // window.location.href = '/dashboard/home'
      }

    } catch (e) {
    }
  }
  React.useEffect(() => {
    getUserBalance()
    if (id) {
      setReferralCode(id)
    }
  }, [])


  const buttonHandler = () => {
    if (!codeStage) {
      return {
        text: "Get Code",
        handler: () => {
          setCodeStage(true)
        }
      }
    } else {
      return {
        text: "Reset Password",
        handler: () => { }
      }
    }
  }

  let bH = buttonHandler()

  let loginHandler = async () => {
    try {
      let { data } = await axios.post(loginAPI, {
        email: email,
        password: password
      }, {
        headers: {
          'Content-Type': 'application/json',
          'clientid': process.env.REACT_APP_CLIENT_ID
        }
      })
      if (data.message == 'Login successful') {

        //change to http only cookie
        Cookies.set('token', data.token)
        toast.success("Login successful", {
          toastId: 'login-success',
          position: 'top-center'
        })
        toast.loading("Redirecting to dashboard", {
          toastId: 'login-loading',
          position: 'top-center'

        })

        setTimeout(() => {
          window.location.href = '/dashboard/home'
        }, 2000)

      } else if (data.message == 'User not found') {
        alert("Invalid credentials")
      }
    } catch (e) {
      if (e.response.data.message == 'User not found') {
        toast.error("Invalid credentials", {
          toastId: 'login-error',
          position: 'top-center'
        })
      }

      if (e.response.data.message == 'Invalid credentials') {
        toast.error("Invalid credentials", {
          toastId: 'login-error',
          position: 'top-center'
        })
      }
    }
  };
  return (
    <LandingWrapper>
      <div className="login-page flex">
        <div className="wrap wrapWidthHeader flex items-center justify-center">
          <div className="main-box flex bg-themeColor2">
            <div className="box-left-side bg-blueDark flex flex-col">
              <div className="title-box flex flex-col">
                <div className="sec-title">Reset Password</div>
                {!codeStage ? <div className="sec-desc">
                  Enter your email
                </div> : <div className="sec-desc">
                  Enter your New Password
                </div>}
              </div>
              <div className="selection-tabs flex items-center">

              </div>
              < div className="form flex flex-col w-full">
                {!codeStage && <div className="input-field flex items-center bg-themeColor1">
                  <div className="input-icon flex items-center justify-center">
                    <MailIcon />
                  </div>
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="txt cleanbtn w-full"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>}
                {codeStage &&
                  <> <div className="input-field flex items-center bg-themeColor1">
                    <div className="input-icon flex items-center justify-center">
                      <PasswordIcon />
                    </div>
                    <input
                      type="password"
                      placeholder="New password"
                      className="txt cleanbtn w-full"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                    <div className="input-field flex items-center bg-themeColor1">
                      <div className="input-icon flex items-center justify-center">
                        <PasswordIcon />
                      </div>
                      <input
                        type="password"
                        placeholder="Re-enter Password"
                        className="txt cleanbtn w-full"
                        onChange={(e) => setReEnteredPassword(e.target.value)}
                      />
                    </div>
                  </>

                }
                {/* sign up  */}

                {activeTab == 'login' && <button
                  onClick={bH.handler}
                  className="btn-continue button flex items-center justify-center">
                  <div className="btn-lbl flex-1 text-center">{bH.text}</div>
                  <div className="btn-icon flex items-center justify-center">
                    <ArrowNextIcon />
                  </div>
                </button>}
                {/* {activeTab == 'signUp' && <button
                  onClick={signUpHandler}
                  className="btn-continue button flex items-center justify-center">
                  <div className="btn-lbl flex-1 text-center">Sign up</div>
                  <div className="btn-icon flex items-center justify-center">
                    <ArrowNextIcon />
                  </div>
                </button>} */}


              </div>
              {/* <div className="forgot-pass flex items-center justify-right ">
                <div className="forgot-pass-lbl">Forgot Password? Reset</div>
              </div> */}
              {/* <div className="socials-login flex flex-col">
                <div className="social-tag flex items-center justify-center relative">
                  <div className="social-lbl absolute bg-blueDark">
                    Or Continue with
                  </div>
                </div>
                <div className="social-icons flex items-center justify-center">
                  <div className="icon flex items-center justify-center bg-themeColor1">
                    <FacebookIcon />
                  </div>
                  <div className="icon flex items-center justify-center bg-themeColor1">
                    <AppleIcon />
                  </div>
                  <div className="icon flex items-center justify-center bg-themeColor1">
                    <GoogleIcon />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="box-right-side flex flex-col">
              <div className="qr-code flex items-center justify-center flex-col">
                <img src="/images/Qrcode.svg" className="code-img" />
                <div className="qr-desc">Login With QR code</div>
                <div className="qr-app">
                  Scan QR code with <span className="text-themeColor">app</span>
                </div>
              </div>
              <div className="url-lock-sec flex flex-col items-center justify-center">
                <div className="url-box flex items-center bg-themeColor1">
                  <div className="lock-icon flex items-center justify-center">
                    <LockIcon />
                  </div>
                  <div className="url">
                    <span className="text-themeColor"></span>
                    {process.env.REACT_APP_WEBSITE}
                  </div>
                </div>
                <div className="slogan">
                  Trade anything anywhere
                  <br /> with {process.env.REACT_APP_NAME}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper >
  );
};

export default Login;
export { ResetPass }
