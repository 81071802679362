import React from "react";

function DollerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="22"
      fill="none"
      viewBox="0 0 13 22"
    >
      <g clipPath="url(#clip0_134_2467)">
        <path
          fill="#fff"
          d="M12.056 14.248c0 .8-.2 1.552-.6 2.256-.384.704-.96 1.296-1.728 1.776-.752.48-1.648.768-2.688.864v1.944H5.48v-1.944c-1.504-.144-2.72-.616-3.648-1.416C.904 16.928.44 15.872.44 14.56h2.928c.048.624.248 1.144.6 1.56.352.4.856.656 1.512.768v-5.28c-1.072-.272-1.944-.544-2.616-.816a4.38 4.38 0 01-1.728-1.344C.672 8.84.44 8.008.44 6.952c0-1.328.464-2.424 1.392-3.288.928-.88 2.144-1.392 3.648-1.536V.184h1.56v1.944c1.408.128 2.536.576 3.384 1.344.864.752 1.352 1.792 1.464 3.12H8.96a2.297 2.297 0 00-.576-1.32c-.336-.4-.784-.672-1.344-.816V9.64c1.072.256 1.944.528 2.616.816.672.272 1.24.712 1.704 1.32.464.592.696 1.416.696 2.472zm-8.832-7.44c0 .656.192 1.168.576 1.536.4.368.96.664 1.68.888V4.384c-.688.08-1.24.328-1.656.744-.4.416-.6.976-.6 1.68zm3.816 10.08c.704-.112 1.256-.392 1.656-.84.4-.464.6-1.016.6-1.656 0-.64-.2-1.136-.6-1.488-.384-.368-.936-.664-1.656-.888v4.872z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_134_2467">
          <path fill="#fff" d="M0 0H13V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DollerIcon;
