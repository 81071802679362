import React from "react";

function VerifiedCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <g clipPath="url(#clip0_204_1409)">
        <path
          fill="#0CAF60"
          fillRule="evenodd"
          d="M9.732 1.437L7.394.14 5.055 1.437l-2.572.736-.735 2.572L.449 7.083l1.299 2.339.735 2.572 2.572.735 2.339 1.299 2.338-1.299 2.572-.735.736-2.572 1.298-2.339-1.298-2.338-.736-2.572-2.572-.736zm1.07 4.109a.833.833 0 00-1.26-1.092L6.56 7.894 5.246 6.377a.833.833 0 00-1.26 1.092l1.945 2.244a.833.833 0 001.26 0l3.61-4.167z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_204_1409">
          <path fill="#fff" d="M0 0H15V15H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default VerifiedCheckIcon;
