import React from "react";
import styles from "./Choose.module.css";
import TitlePart from "../../TitlePart/TitlePart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faCubes,
  faPhone,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import Lottie from "lottie-react";
import lottieAnim from "./lottieAnim.json";

const Choose = () => {
  return (
    <section className="wrapper">
      <div className={`${styles.choose} contain`}>
        <TitlePart
          title={`${process.env.REACT_APP_NAME} puts the odds in your favor with real-time data and analytics to guide your trading and portfolio`}
          top="WHY CHOOSE US"
          invert
        />
        <div className={styles.grid}>
          <Box
            ico={faCubes}
            title="Real-time data"
            para="Our traders benefit from real-time data to stay ahead of market trends and swings."
          />
          <Box
            ico={faUsersCog}
            title="Trade on your terms"
            para="Enjoy trading on your terms with web, mobile and even MT5 options available."
          />
          <Lottie
            className={styles.lottie}
            animationData={lottieAnim}
            loop={true}
          />
          <Box
            ico={faAward}
            title="Advanced analytics"
            para="We provide our traders with advanced analytics to help predict swings before they happen."
          />
          <Box
            ico={faPhone}
            title="Customer support"
            para="Our award-winning customer support provides 24/5 multilingual support to all our clients."
          />
        </div>
      </div>
    </section>
  );
};

export default Choose;

const Box = ({ ico, title, para }) => {
  return (
    <div className={styles.box}>
      <div className={styles.icoWrapp}>
        <FontAwesomeIcon icon={ico} />
      </div>
      <h4 className="nunito">{title}</h4>
      <p>{para}</p>
    </div>
  );
};
