import React from "react";

import TitlePart from "../../TitlePart/TitlePart";

import { icon1, icon2, icon3, icon4, icon11 } from "../../../images/images";
import styles from "./Products.module.css";

import SingleProduct from "./SingleProduct";

const Products = () => {
  const products = [
    {
      img: icon1,
      tite: "Forex",
      para: `Enter one of the most fluid markets in the world with traditional and exotic pairs unique to ${process.env.REACT_APP_NAME}.`,
      btn: "Get Started",
    },
    {
      img: icon2,
      tite: "Crypto",
      para: "Jump into the ever-volatile crypto market with access to mainstream coins and thousands of altcoins.",
      btn: "Learn More",
    },
    {
      img: icon3,
      tite: "Stocks",
      para: "Buy and sell thousands of blue chip and emerging stocks with confidence from around the world.",
      btn: "Learn More",
    },
    {
      img: icon11,
      tite: "Indices",
      para: `Whether the S&P 500 or something else, ${process.env.REACT_APP_NAME} has the indices that perform over the long term.`,
      btn: "Learn More",
    },
    {
      img: icon4,
      tite: "Commodities",
      para: "Access the world’s oldest market and quickly trade in forestry, agriculture, energies and minerals.",
      btn: "Learn More",
    },
  ];
  return (
    <section className="wrapper">
      <div className={`contain ${styles.products}`}>
        <TitlePart
          title="Our current product slate includes five asset classes with thousands of digital assets available to buy and sell."
          top="Products"
        />
        <div className={styles.productGrid}>
          {products.map((el, i) => (
            <SingleProduct {...el} key={i} />
          ))}

          <div className={styles.lastBox}>
            <h4>Interested in taking the jump?</h4>
            <p>
              Let’s see how {process.env.REACT_APP_NAME} can change the way you think about
              trading.
            </p>
            <button
              onClick={() => window.location.href = "/register"}
              className={styles.btn}>START TRADING</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
