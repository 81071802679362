import React from "react";
import styles from "./Hero.module.css";
const SingleSlide = ({ img, title, desc, sub }) => {
  return (
    <div className={styles.slide}>
      <div className={styles.leftSlide}>
        <h4>{sub}</h4>
        <h3 className="nunito">{title}</h3>
        <p>{desc}</p>
        <button
          onClick={() => window.location.href = "/register"}
          className={styles.ctaInverBtn}>Start Trading</button>
      </div>
      <img src={img} alt="" />
    </div>
  );
};

export default SingleSlide;
