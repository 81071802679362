import React from "react";

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#C4C4C4"
        d="M19.85 12a8.1 8.1 0 00-1.66-4.921 7.834 7.834 0 00-4.277-2.838 7.705 7.705 0 00-5.084.445 7.908 7.908 0 00-3.74 3.54 8.138 8.138 0 00-.814 5.138 8.045 8.045 0 002.458 4.56 7.77 7.77 0 004.689 2.055 7.73 7.73 0 004.936-1.322l1.088 1.664a9.663 9.663 0 01-6.17 1.652 9.713 9.713 0 01-5.86-2.567 10.056 10.056 0 01-3.073-5.7 10.172 10.172 0 011.016-6.423 9.885 9.885 0 014.675-4.425A9.631 9.631 0 0114.39 2.3a9.793 9.793 0 015.347 3.548 10.127 10.127 0 012.075 6.15v1.5c.002.75-.233 1.481-.67 2.085a3.434 3.434 0 01-1.754 1.268 3.369 3.369 0 01-2.15-.045 3.442 3.442 0 01-1.702-1.341 4.881 4.881 0 01-2.411 1.4c-.92.22-1.882.165-2.77-.16a4.91 4.91 0 01-2.243-1.666 5.083 5.083 0 01-.426-5.41 4.954 4.954 0 011.952-2.011 4.825 4.825 0 015.31.38h1.96v5.5c0 .399.156.78.432 1.061.276.282.65.44 1.04.44s.764-.158 1.04-.44c.276-.28.43-.662.43-1.06V12zm-7.844-3c-.582 0-1.151.176-1.635.505-.484.33-.86.799-1.083 1.347a3.055 3.055 0 00-.168 1.733c.114.582.394 1.117.805 1.536.412.42.936.705 1.507.821a2.89 2.89 0 001.7-.17 2.955 2.955 0 001.32-1.106 3.042 3.042 0 00-.366-3.787 2.913 2.913 0 00-2.08-.88z"
      ></path>
    </svg>
  );
}

export default MailIcon;
