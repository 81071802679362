import React from "react";
import Wrapper from "../../layouts/Wrapper";
import { HandIcon, QuestionIcon, CopyIcon } from "../../assets/icons";

const Affiliate = () => {
  const [value, setValue] = React.useState("");
  const [isCommunity, setIsCommunity] = React.useState(false);
  const Tips = () => {
    return (
      <div className="tips-section flex flex-col">
        <div className="sec-tag">Tips To Get Started</div>
        <div className="tip-box flex flex-col bg-themeColor2">
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Register.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Generate Referral link.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Send the generated link to your friends and family to register on the platform.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              When your friends register and make deposits,you earn 5% from your direct referral and 3 and 1% on their downlines respectivesly.{" "}
              <span

                onClick={() => {
                  window.location.href = "https://docs.qudaix.com/platform/affiliate-rewards-programme";
                }}
                className="text-themeColor">Learn More.</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <div className="affiliate-page h-screen flex">
        <div className="wrap wrapWidth flex text-white">
          <div className="page-left flex flex-col">
            <div className="page-header flex items-center justify-between">
              <div className="page-title">Affiliate and Bonus Dashboard</div>
              <div className="question-icon flex items-center justify-center bg-themeColor2">
                <QuestionIcon />
              </div>
            </div>
            <div className="left-top-sec flex">
              <div className="top-sec-left flex flex-col">
                {/* <div className="data-field flex flex-col">
                  <div className="f-tag text-lightGreen">Deposit Bonus</div>
                  <div className="val text-center bg-themeColor1">$100</div>
                </div> */}
                <div className="data-field flex flex-col">
                  <div className="sec-tag text-themeColor">
                    Affiliate Members
                  </div>
                  <div className="f-tag text-lightGreen">
                    Active/<span className="text-white">All</span>
                  </div>
                  <div className="val text-center bg-themeColor1">
                    <span className="text-lightGreen">{localStorage.getItem('refcount')}</span> /{localStorage.getItem('refcount')}
                  </div>
                </div>
                <div className="data-field flex flex-col">
                  <div className="sec-tag text-themeColor">
                    Affiliate Earnings
                  </div>
                  <div className="f-tag text-lightGreen">
                    Individual Ref.Earned
                  </div>
                  <div className="val text-center bg-themeColor1">${localStorage.getItem('refearn')}</div>
                </div>
                {/* <div className="data-field flex flex-col">
                  <div className="f-tag text-lightGreen">
                    Community Ref.Earned
                  </div>
                  <div className="val text-center bg-themeColor1">$100k</div>
                </div> */}
              </div>
              <div className="top-sec-right flex flex-col">
                <div className="address-sec flex flex-col">
                  <div className="selection-lbl">Referral Link</div>
                  <div className="address-box flex items-center bg-themeColor2">
                    <div className="a-code">
                      {process.env.REACT_APP_WEBSITE}/login/
                      {localStorage.getItem("username")}
                    </div>
                    <button className="btn-copy button">
                      <div className="btn-lbl">Copy</div>
                      <div className="btn-icon">
                        <CopyIcon />
                      </div>
                    </button>
                  </div>
                </div>
                {/* {isCommunity && ( */}
                {1 == 2 && (
                  <div className="address-sec flex flex-col">
                    <div className="selection-lbl">Community Link</div>
                    <div className="address-box flex items-center bg-themeColor2">
                      <div className="a-code">
                        {process.env.REACT_APP_WEBSITE}/{value}
                      </div>
                      <button className="btn-copy button">
                        <div className="btn-lbl">Copy</div>
                        <div className="btn-icon">
                          <CopyIcon />
                        </div>
                      </button>
                    </div>
                  </div>
                )}

                {1 == 2 && (
                  <div className="address-sec flex flex-col">
                    <div className="selection-lbl">Community Link</div>
                    <div className="address-box flex items-center bg-themeColor2">
                      <input
                        type="text"
                        className="a-code cleanbtn"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                      <button
                        className="btn-copy button"
                        onClick={() => setIsCommunity(true)}
                      >
                        <div className="btn-lbl">Create</div>
                        <div className="btn-icon">
                          <HandIcon />
                        </div>
                      </button>
                    </div>
                  </div>
                )}

                {/* <div className="action flex items-center">
                  <button className="btn-create button">
                    Create community
                  </button>
                </div> */}
                <div className="affiliate-mechanism-sec flex flex-col">
                  <div className="sec-tag text-themeColor">
                    Affiliate mechanism
                  </div>
                  <div className="affiliate-list flex flex-col">
                    <div className="item flex items-center">
                      <div className="dot" />
                      <p className="desc">Individual: 5-3-1%</p>
                    </div>
                    <div className="item flex items-center">
                      <div className="dot" />
                      <p className="desc">Team:up to 1.5% Cashback on montly individual deposits</p>
                    </div>
                    {/* // <div className="item flex items-center">
                    //   <div className="dot" />
                    //   <p className="desc">2.5%/community pool</p>
                    // </div>
                    // <div className="item flex items-center">
                    //   <div className="dot" />
                    //   <p className="desc">0.5%/Leaders</p>
                    // </div> */}
                  </div>
                </div>
              </div>
            </div>
            <Tips />
          </div>
          <div className="page-right flex flex-col items-center">
            <div className="flex items-center justify-start w-full">
              <div className="hand-icon flex items-center justify-center">
                <HandIcon />
              </div>
            </div>
            <div className="sec-tag">OUR COMMITMENT</div>
            <div className="title">
              “GROWTH FOR US MEANS ENSURING PEOPLE ARE IN A POSITION TO SUCCEED”
            </div>
            <div className="sec-description">
              At {process.env.REACT_APP_NAME}, we believe in recognising and rewarding exceptional
              leadership and the strong communities they build.
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Affiliate;
