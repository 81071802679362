import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const Private = ({ children }) => {
  // const { userToken } = useSelector((state) => state.authReducer);
  let userToken = Cookies.get("token");
  return userToken ? children : <Navigate to="/" />;
};

export default Private;
