import React from "react";

function DoubleIcons() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      fill="none"
      viewBox="0 0 9 12"
    >
      <path
        fill="#A4A8AB"
        d="M4.243 2.828L1.415 5.657 0 4.243 4.243 0l4.243 4.243-1.415 1.414-2.828-2.829zM4.243 12L0 7.757l1.415-1.414 2.828 2.829 2.828-2.83 1.415 1.415L4.243 12z"
      ></path>
    </svg>
  );
}

export default DoubleIcons;
