import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_204_1350)">
        <path
          fill="#fff"
          d="M6.5 11.5a4.5 4.5 0 110-9.002 4.5 4.5 0 010 9.002zm.5 10a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm10-10a4.501 4.501 0 01-1.722-8.657A4.5 4.5 0 1117 11.5zm0 10a4.5 4.5 0 110-9 4.5 4.5 0 010 9zM6.5 9.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm.5 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm10-10a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_204_1350">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default HomeIcon;
