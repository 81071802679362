import React from "react";

function ExchangeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_204_1355)">
        <path
          fill="#F0F4F7"
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16.001A8 8 0 0012 20zm-5-7h9v2h-4v3l-5-5zm5-4V6l5 5H8V9h4z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_204_1355">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ExchangeIcon;
