import React, { useState, useEffect } from "react";
import Wrapper from "../../layouts/Wrapper";
import ReactCodeInput from "react-verification-code-input";
import {
  QuestionIcon,
  ArrowDownIcon,
  LoadIcon,
  LinkIcon,
  CoinIcon,
  ArrowNextIcon,
  HandIcon,
} from "../../assets/icons";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const Withdraw = ({ balanceArray, balance, setBalance }) => {
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);

  let token = Cookies.get("token");
  let clientid = process.env.REACT_APP_CLIENT_ID
  const getUserBalance = async () => {
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          'clientid': clientid,
          'x-access-token': token
        }
      });
      setBalance(data.data.balance)
    } catch (e) {
    }
  }
  React.useEffect(() => {
    getUserBalance()
  }, [])

  React.useEffect(() => {
    //set default token
    setToken1(balanceArray[0]);
    setTokenBalance(balanceArray[0]?.balance);
  }, [balanceArray]);
  const [pin, setPin] = useState();
  const [token1, setToken1] = useState({ img: "/images/btc.png", lbl: "BTC" });
  const [hide1, setHide1] = useState(false);




  const handleCode = (e) => {
    setPin(e);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide1(false);
    });
  }, []);


  const handleWithdraw = async () => {
    toast.loading("Withdrawing...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      toastId: "withdraw",
    })
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard/withdraw";
      // let api = "http://localhost:8080/dashboard/withdraw";
      let { data } = await axios.post(api, {
        amount: amount,
        currency: token1.currency,
        wallet: address,
        pin: pin
      }, {
        headers: {
          "Content-Type": "application/json",
          'clientid': clientid,
          'x-access-token': token
        }
      });
      getUserBalance()
      toast.update("withdraw", {
        render: data.message,
        type: toast.TYPE.SUCCESS,
      })
      setTimeout(() => {
        toast.dismiss("withdraw")
      }, 3000)

    } catch (e) {
      toast.update("withdraw", {
        render: e.response.data.message,
        type: toast.TYPE.ERROR,
        autoClose: 3000
      })
      setTimeout(() => {
        toast.dismiss("withdraw")
      }, 3000)
    }
  }
  return (
    <Wrapper>
      <div className="withdraw-page h-screen flex">
        <div className="wrap wrapWidth flex text-white">
          <div className="page-left flex flex-col">
            <div className="page-header flex items-center justify-between">
              <div className="page-title">Withdraw </div>
              <div className="question-icon flex items-center justify-center bg-themeColor2">
                <QuestionIcon />
              </div>
            </div>
            <div className="coin-selection flex flex-col">
              <div className="selection-lbl">Coin :</div>
              <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                <div className="category flex aic bg-themeColor2">
                  <div
                    className="cbox cleanbtn flex aic rel pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setHide1(!hide1);
                    }}
                  >
                    <div className="slt flex aic">
                      <div className="selected-token flex items-center justify-center bg-themeColor1">
                        <div className="icon flex items-center justify-center">
                          <img
                            src={`https://nowpayments.io/images/coins/${token1?.currency}.svg`}
                            className="img"
                          />
                          <div className="token-name">
                            {token1 ? token1.name : ""}
                          </div>
                        </div>
                      </div>
                      <div className="unit-name flex aic font s14 b4">
                        <span
                          className="unit-eng flex aic font s14 b4"
                          placeholder="Plano"
                        >
                          {token1 ? token1.name : ""}
                        </span>
                      </div>
                    </div>

                    <div className="arrow-icon flex items-center justify-center">
                      <ArrowDownIcon />
                    </div>
                  </div>
                </div>
                <div className={`block flex aic abs ${hide1 ? "show" : ""}`}>
                  <div className="manue flex aic col anim">
                    {balanceArray.map((item, index) => (
                      <div
                        key={index}
                        className="slt flex aic"
                        onClick={(e) => {
                          setHide1(!hide1);
                          setToken1(item);
                          setTokenBalance(item.balance)
                        }}
                      >
                        <div className="unit-name flex aic font s14 b4">
                          <span className="unit-eng flex aic font s14 b4">
                            {item.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="total-balance flex items-center">
              <div className="b-lbl">Total Balance : </div>
              <div className="b-val">{tokenBalance > 0 ? tokenBalance?.toFixed(2) : "0"} USD</div>
              <div
                onClick={() => window.location.reload()}
                className="load-icon flex items-center justify-center">
                <LoadIcon />
              </div>
            </div>
            <div className="input-field flex items-center bg-themeColor2 my-5">
              <div className="icon flex items-center justify-center">
                <LinkIcon />
              </div>
              <input
                //disable
                // disabled
                type="text"
                onChange={(e) => setAddress(e.target.value)}

                placeholder="Withdraw Address"
                className="txt cleanbtn w-full"
              />
              <button className="btn-action button"></button>
            </div>
            <div className="input-field flex items-center bg-themeColor2">
              <div className="icon flex items-center justify-center">
                <CoinIcon />
              </div>
              <input
                type="text"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="txt cleanbtn w-full"
              />
              <button
                onClick={() => {
                  setAmount(tokenBalance?.toFixed(2))
                  // alert(amount)
                }
                }

                className="btn-action button">Max</button>
            </div>
            <div className="total-balance flex items-center">
              <div className="b-lbl">Minimum Withdrawal :</div>
              <div className="b-val">$ 10</div>
            </div>
            <div className="action-section flex items-center justify-between">
              <div className="pin-side flex items-center">
                <div className="tag">Security Pin</div>
                <div className="access-code flex items-center">
                  <ReactCodeInput
                    className="code-box"
                    fields={4}
                    onChange={handleCode}
                    type="password"
                  />
                </div>
              </div>
              <button
                onClick={handleWithdraw}
                className="btn-continue button flex items-center justify-center">
                <div className="btn-lbl flex-1 text-center">
                  Proced Withdrawal
                </div>
                <div className="btn-icon flex items-center justify-center">
                  <ArrowNextIcon />
                </div>
              </button>
            </div>
            <div className="tips-section flex flex-col">
              <div className="sec-tag">Tips</div>
              <div className="tip-box flex flex-col bg-themeColor2">
                <div className="tip-item flex">
                  <div className="dot"></div>
                  <div className="desc">
                    Please make sure your desposit address is same with the network you selected.
                  </div>
                </div>
                <div className="tip-item flex">
                  <div className="dot"></div>
                  <div className="desc">
                    Coins will be deposited in your wallet within 1hrs in normal cases and in abnormal cases may be upto 24-48hrs.
                  </div>
                </div>
                <div className="tip-item flex">
                  <div className="dot"></div>
                  <div className="desc">
                    Until 2 confrimations are made.an equivalent amount of your
                    assets will be temporarily unavailable for witdrawals.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-right flex flex-col items-center">
            <div className="flex items-center justify-start w-full">
              <div className="hand-icon flex items-center justify-center">
                <HandIcon />
              </div>
            </div>
            <div className="sec-tag">YOUR DREAMS ARE VALID</div>
            <div className="title">
              “WE EXIST TO INSPIRE YOU TO DREAM BIGGER”
            </div>
            <div className="sec-description">
              " At {process.env.REACT_APP_NAME}, we exist to inspire you to dream bigger. We believe
              that within each and every one of us lies incredible potential and
              untapped possibilities. We're here to guide you on a
              transformative journey, empowering you to reach new heights and
              achieve your wildest dreams."
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Withdraw;
