import React from "react";
import Button from "../../Button/Button";
import styles from "./About.module.css";
import { bluphone, check } from "../../../images/images";

const About = () => {
  const data = [
    "Best-in-class security",
    "MT5, web and mobile trading",
    "Ultra-fast transactions",
    "Tight margins",
    "Next-generation portfolio",
    "Unlimited trading opportunities",
  ];
  return (
    <section className="wrapper">
      <div className={`contain ${styles.about}`}>
        <img src={bluphone} alt="" />
        <div className={styles.rightAbout}>
          <h4>About us</h4>
          <h3 className="nunito">
            {process.env.REACT_APP_NAME} is a custom-built web broker that is designed to
            elevate your trading experience
          </h3>
          <p>
            {process.env.REACT_APP_NAME} is a leading web broker empowering clients for years
            to better their financial freedom. Our team has built our platform
            from the ground up to be a better option for those looking to bring
            their trading to the next level. Now is the time to embrace your
            opportunity and see how {process.env.REACT_APP_NAME} can improve your financial
            future.
          </p>
          <div className={styles.grid}>
            {data.map((el, i) => (
              <div className={styles.option} key={i}>
                <img src={check} alt="" />
                <p>{el}</p>
              </div>
            ))}
          </div>
          <Button btnStyles={{ padding: "1rem 3rem" }} name="Get Trading" cta />
        </div>
      </div>
    </section>
  );
};

export default About;
