import React, { useState, useEffect } from "react";
import Wrapper from "../../layouts/Wrapper";
import {
  QuestionIcon,
  ArrowDownIcon,
  CopyIcon,
  WarningIcon,
  ClockIcon,
} from "../../assets/icons";

const Deposit = () => {
  const [tab, setTab] = useState("Crypto");
  const [tokenTab, setTokenTab] = useState("BTC");
  const tokenList = [
    { lbl: "BTC" },
    // { lbl: "BEP2" },
    // { lbl: "BEP20 (BSC)" },
    // { lbl: "TRC20" },
  ];
  const [token1, setToken1] = useState({ img: "/images/btc.png", lbl: "BTC" });
  const [hide1, setHide1] = useState(false);

  const tokensList = [
    { img: "/images/btc.png", lbl: "BTC" },
    { img: "/images/usdt.png", lbl: "USDT" },
    { img: "/images/trx.png", lbl: "BUSD" },
    { img: "/images/btcoin.png", lbl: "BTC" },
  ];

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide1(false);
    });
  }, []);
  const Tips = () => {
    return (
      <div className="tips-section flex flex-col">
        <div className="sec-tag">Tips</div>
        <div className="tip-box flex flex-col bg-themeColor2">
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              If you have deposited,funds will automatically deposited to your account.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Coins will be deposited after 1 newtwork confrimation.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Until 2 confrimations are made.an equivalent amount of your assets
              will be temporarily unavailable for witdrawals.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              You could check the deposit status at{" "}
              <span
                onClick={() => {
                  window.location.href = "/dashboard/myinvestments";
                }}
                className="text-themeColor">Deposit Records.</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <div className="deposit-page h-screen flex">
        <div className="wrap wrapWidth flex text-white">
          <div className="page-left flex flex-col">
            <div className="page-header flex items-center justify-between">
              <div className="page-title">Deposit </div>
              <div className="question-icon flex items-center justify-center bg-themeColor2">
                <QuestionIcon />
              </div>
            </div>
            <div className="tabs-sec flex items-center">
              <div className="tabs-box flex items-center justify-center">
                <button
                  className={`btn-tab button ${tab === "Crypto" ? "active" : ""
                    }`}
                  onClick={(e) => setTab("Crypto")}
                >
                  Crypto
                </button>
                <button
                  className={`btn-tab button ${tab === "Fiat" ? "active" : ""}`}
                  onClick={(e) => setTab("Fiat")}
                >
                  Fiat
                </button>
              </div>
            </div>
            <div className="coin-selection flex flex-col">
              <div className="selection-lbl">Select Plan :</div>
              <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                <div className="category flex aic bg-themeColor2">
                  <div
                    className="cbox cleanbtn flex aic rel pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setHide1(!hide1);
                    }}
                  >
                    <div className="slt flex aic">
                      <div className="selected-token flex items-center justify-center bg-themeColor1">
                        <div className="icon flex items-center justify-center">
                          <img
                            src={token1 ? token1.img : "/images/btc.png"}
                            className="img"
                          />
                          <div className="token-name">
                            {token1 ? token1.lbl : ""}
                          </div>
                        </div>
                      </div>
                      <div className="unit-name flex aic font s14 b4">
                        <span
                          className="unit-eng flex aic font s14 b4"
                          placeholder="Plano"
                        >
                          {token1 ? token1.lbl : ""}
                        </span>
                      </div>
                    </div>

                    <div className="arrow-icon flex items-center justify-center">
                      <ArrowDownIcon />
                    </div>
                  </div>
                </div>
                <div className={`block flex aic abs ${hide1 ? "show" : ""}`}>
                  <div className="manue flex aic col anim">
                    {tokensList.map((item, index) => (
                      <div
                        key={index}
                        className="slt flex aic"
                        onClick={(e) => {
                          setHide1(!hide1);
                          setToken1(item);
                        }}
                      >
                        <div className="unit-name flex aic font s14 b4">
                          <span className="unit-eng flex aic font s14 b4">
                            {item.lbl}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="total-balance flex items-center">
              <div className="b-lbl">Total Balance : </div>
              <div className="b-val">0.0121285425 BTC</div>
            </div>
            <div className="address-sec flex flex-col">
              <div className="selection-lbl">BTC Address</div>
              <div className="address-box flex items-center bg-themeColor2">
                <div className="a-code">bc1qXY2kGdygjrsqtzE2n0yrf2XY3</div>
                <button className="btn-copy button">
                  <div className="btn-lbl">Copy</div>
                  <div className="btn-icon">
                    <CopyIcon />
                  </div>
                </button>
              </div>
            </div>
            <Tips />
          </div>
          <div className="page-right flex flex-col">
            <div className="sec-title flex items-center">
              <div className="tag">Deposit Network</div>
              <div className="icon flex items-center justify-center">
                <WarningIcon />
              </div>
            </div>
            <div className="tabs-sec flex items-center">
              <div className="tabs-box flex items-center justify-center">
                {tokenList.map((item, index) => (
                  <button
                    key={index}
                    className={`btn-tab button ${tokenTab === item.lbl ? "active" : ""
                      }`}
                    onClick={(e) => setTokenTab(item.lbl)}
                  >
                    {item.lbl}
                  </button>
                ))}
              </div>
            </div>
            <div className="avg-time flex items-center justify-center">
              <div className="clock-icon flex items-center justify-center">
                <ClockIcon />
              </div>
              Average Arrival Time : <span className="time">3 Minutes</span>
            </div>
            <div className="qrcode-sec flex flex-col items-center">
              <div className="tag">BTC Address</div>
              <img src="/images/Qrcode1.svg" className="qr-code" />
            </div>
            <div className="description-sec flex items-start flex-col">
              <div className="warn-icon flex items-center justify-center">
                <WarningIcon />
              </div>
              <h1 className="sec-tag">Send Only BTC to this Address !</h1>
              <p className="desc">
                Sending coin or token other than BTC to this Address may reault
                in the los of your deposit.
              </p>
            </div>
            <Tips />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Deposit;
