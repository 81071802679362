import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenSideMenu } from "../../store/reducers/globalReducer";
import {
  DashboardIcon,
  SettingIcon,
  BellIcon,
  WalletIcon,
  ArrowDownIcon,
  MenuIcon,
} from "../../assets/icons";

import SearchBox from "../SearchBox";
import { toast } from "react-toastify";

const Header = () => {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    document.addEventListener("click", () => {
      document.body.style.overflow = "inherit";
      setShowNotification(false);
    });
  }, []);

  const notificationsList = [
    // { lbl: "Notification 1" }
  ];

  return (
    <div className="header-camp flex bg-blueDark">
      <div className="wrap wrapWidth flex items-center">
        <div className="left-side flex items-center">
          <div className="mobile-menu-sec flex items-center">
            <div
              className="menu-icon flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setOpenSideMenu(true));
              }}
            >
              <MenuIcon />
            </div>
            <div className="menu-logo flex items-center">
              <img
                width="155"
                src={process.env.REACT_APP_LOGO}
                className="logo-img"
              />
            </div>
          </div>
          <div className="dashboard-icon flex items-center justify-center cursor-pointer">
            {/* <DashboardIcon /> */}
          </div>
          {/* <SearchBox /> */}
        </div>
        <div className="right-side flex items-center justify-end">
          {/* <div className="language-dropdown flex items-center justify-center bg-themeColor1">
            <div className="flag-icon flex items-center justify-center">
              <img src="/images/eth.svg" className="img" />
            </div>
            <div className="language-name">English</div>
            <div className="icon">
              <ArrowDownIcon />
            </div>
          </div> */}
          <div
            onClick={() => {
              localStorage.removeItem("token");
              toast.success("Logout Successful", {
                position: toast.POSITION.TOP_CENTER,
              });

              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }}
            className="wallet-icon ico flex items-center justify-center bg-themeColor1">
            <WalletIcon />
          </div>
          <div
            onClick={() => {
              window.location.href = "/dashboard/setting";
            }}
            className="setting-icon ico flex items-center justify-center bg-themeColor1">
            <SettingIcon />
          </div>
          <div className="flex items-center justify-center relative">
            <div
              className="bell-icon ico flex items-center justify-center bg-themeColor1 relative"
              onClick={(e) => {
                e.stopPropagation();
                setShowNotification(!showNotification);
              }}
            >
              <BellIcon />
              <div className="numb absolute flex items-center justify-center">
                0
              </div>
            </div>

            {/* Notification Dropdown  */}
            <div
              className={`notification-menu absolute flex flex-col ${showNotification ? "show" : "hide"
                }`}
            >
              {notificationsList.map((item, index) => (
                <div key={index} className="tag">
                  {item.lbl}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Header;
