import React from "react";

function PasswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_1_1944)">
        <path
          fill="#C4C4C4"
          d="M14.309 22c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-1-7.208a2.5 2.5 0 112 0V16h-2v-3.208z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_1944">
          <path
            fill="#fff"
            d="M0 0H23.535V24H0z"
            transform="translate(.238)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PasswordIcon;
