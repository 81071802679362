import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Footer.module.css";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { logo } from "../../../images/images";
import { Link } from "react-router-dom";
const Footer = () => {
  const footerItems = [
    {
      title: "Products",
      items: [
        { name: "Forex", to: "#" },
        { name: "Crypto", to: "#" },
        { name: "Stocks", to: "#" },
        { name: "Indices", to: "#" },
        { name: "Commodities", to: "#" },
        { name: "Range of Markets", to: "#" },
      ],
    },
    // {
    //   title: "Legal",
    //   items: [
    //     { name: "Terms and Conditions", to: "#" },
    //     { name: " Privacy Poliscy", to: "#" },
    //     { name: " KYC", to: "#" },
    //     { name: "Complaints", to: "#" },
    //   ],
    // },
    {
      title: "Contact Us",
      items: [
        {
          name: process.env.REACT_APP_EMAIL, to: "mailto:" + process.env.REACT_APP_EMAIL
        },
        {
          name: process.env.REACT_APP_ADDRESS, to: "#"
        },
        {
          name: process.env.REACT_APP_PHONE, to: `tel:${process.env.REACT_APP_PHONE}`
        },
        {
          name: "Telegram", to: `${process.env.REACT_APP_TELEGRAM}`
        }


      ],
    },
  ];
  return (
    <section className={styles.footer}>
      <div
        style={{ position: "relative", marginTop: "40px" }}
        className="wrapper"
      >
        <div className={styles.topBg}></div>
        <div className={styles.bottomBg}></div>
        <footer className={styles.footerGrid}>
          <div className={styles.greenBox}>
            <Link to="/">
              <img src={process.env.REACT_APP_LOGO} alt="#" />
            </Link>
            <p>
              ${process.env.REACT_APP_NAME} is disrupting the web-broker space. With our
              best-in-class security and leading data, our team is changing the
              way you think of trading digital assets. We are not your father’s
              broker, and we are pro`ud to say that!
            </p>
          </div>
          <div className={styles.topWrapper}>
            <div className={` ${styles.topFooter}`}>
              {footerItems.map((navItems, i) => (
                <div className={styles.footCol} key={i}>
                  <div className={styles.footHead}>
                    <h4 className="nunito">{navItems.title}</h4>
                    <div className={styles.line}></div>
                  </div>
                  {navItems.items.map((item, id) => (
                    <Link to={item.to} key={id}>
                      <FontAwesomeIcon icon={faArrowRight} />
                      {item.name}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.bottomWrapper}>
            <div className={styles.bottomFooter}>
              <p>
                ${process.env.REACT_APP_NAME} is a leading web broker who has been one of the
                leaders in the financial space for years. We provide our clients
                with real-time data, leading analytics and access to
                professional trading tools to take your trades to the next
                level. Upgrade your trading game, and see why ${process.env.REACT_APP_NAME} is
                the leading web broker today!
              </p>
            </div>
          </div>
        </footer>
      </div>
    </section>
  );
};

export default Footer;
