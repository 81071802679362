import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Wrapper from "../../layouts/Wrapper";
import {
  QuestionIcon,
  ArrowDownIcon,
  CalculatorIcon,
  DollerIcon,
  ArrowNextIcon,
  WarningIcon,
  ExchangeIcon,
} from "../../assets/icons";
import axios from "axios";
import { toast } from "react-toastify";
const Deposit1 = ({ balanceArray }) => {








  const [balanceArr, setBalanceArr] = React.useState([]);
  let token = Cookies.get("token");
  if (!token) {
    window.location.href = "/";
  }
  let clientid = process.env.REACT_APP_CLIENT_ID;
  const getUserBalance = async () => {
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          clientid: clientid,
          "x-access-token": token,
        },
      });
      if (data.error == "Invalid token") {
        toast.error("Session Expired", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "sessionExpired",
        });
        toast.loading("Redirecting to login page", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "sessionExpidred",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
      let refcount = 0;
      if (data?.data?.levelone?.length > 0) {
        refcount = data?.data.levelone.length;
      }

      // setUserData(data?.data);
      // setBalance(Number(data?.data?.balance).toFixed(2));
      // setTransactions(data?.txn);
      // setBalanceList(data?.balances);
      setBalanceArr(data?.balances);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("firstName", data.data.firstName);
      localStorage.setItem("lastName", data.data.lastName);
      localStorage.setItem("refearn", data.data.affiliateEarnings);
      localStorage.setItem('refcount', refcount);

    } catch (e) {
    }
  };
  React.useEffect(() => {
    getUserBalance();
  }, []);

  const [token1, setToken1] = useState();
  const [hide1, setHide1] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [amount, setAmount] = useState(200)
  const [plan, setPlan] = useState({})
  const [plans, setPlans] = useState([]);

  const [value, setValue] = useState(200)



  const getPlans = async () => {
    let api = "https://yield-jet.sentrynode.io/"
    // let api = 'http://localhost:8080/'
    let url = api + "dashboard/plans"
    let token = Cookies.get("token");
    let clientid = process.env.REACT_APP_CLIENT_ID;
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        'clientid': clientid,
        'x-access-token': token
      }
    })

    setPlans(data.data)
    setPlan(data.data[0])
  }
  const tokensList = [
    { img: "/images/btc.png", lbl: "BTC", slug: "btc" },
    { img: "/images/usdt.png", lbl: "USDT", slug: "usdttrc20" },
    // { img: "/images/trx.png", lbl: "BUSD" },
    // { img: "/images/btcoin.png", lbl: "BTC" },
  ];

  // const plans = [{

  //   name: "Basic",
  //   apr: "8",
  //   duration: "18 Days",
  //   payout: "daily",
  //   total: "144",
  // }];


  useEffect(() => {
    getPlans()
    setToken1(balanceArr[0])
  }, [balanceArr]);
  const initiateDeposit = async () => {
    if (!plan) {
      toast.error('Please select a plan', {
        position: toast.POSITION.TOP_CENTER
      })
      return
    }
    if (amount < plan?.min) {
      // alert('Amount must be greater than ' + plan.min)
      toast.error('Minimum Investment is ' + plan?.min, {
        position: toast.POSITION.TOP_CENTER
      })
      return
    }
    toast.loading('Initiating Deposit...', {
      position: toast.POSITION.TOP_CENTER,
      toastId: 'loading'
    })
    // window.open('/dashboard/deposit2', '_blank')
    try {
      let api = "https://yield-jet.sentrynode.io/"
      // let api = 'http://localhost:8080/'
      let url = api + "dashboard/invest"

      let body = {
        amount: amount,
        plan: plan.plan,
        currency: token1.currency
      }
      let token = Cookies.get("token");
      let clientid = process.env.REACT_APP_CLIENT_ID;
      const { data } = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
          'clientid': clientid,
          'x-access-token': token
        }
      })
      localStorage.setItem('address', data.payment_wallet)
      localStorage.setItem('amount', data.pay_amount)
      localStorage.setItem('pay_id', data.payment_id)
      localStorage.setItem('currency', data.pay_currency)
      localStorage.setItem('network', data.data.network)

      toast.dismiss('loading')
      window.location.href = '/dashboard/deposit2'
    } catch (e) {
      console.log(e)
      toast.dismiss('loading')
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER

      })
    }



    // window.('/dashboard/deposit2', '_blank')



  };
  useEffect(() => {
    document.addEventListener("click", () => {
      setHide1(false);
    });

  }, []);

  useEffect(() => {
  }, [
    plans
  ])

  const Tips = () => {
    return (
      <div className="tips-section flex flex-col">
        <div className="sec-tag">Tips</div>
        <div className="tip-box flex flex-col bg-themeColor2">
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Choose your preferred investment Plan(in this case we have only 9% for 18days plans).
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Select your preferred Payment currency.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              Input the amount you want to deposite in USD.
            </div>
          </div>
          <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
              You can calculate your profit using the profit calculator.
            </div>
          </div>
          {/* <div className="tip-item flex">
            <div className="dot"></div>
            <div className="desc">
            proceed to complete your desposit{" "}
            <span
              onClick={() => {
                window.location.href = "/dashboard/myinvestments";
              }}
              className="text-themeColor">Deposit Records.</span>
          </div>
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <Wrapper>
      <div className="deposit1-page h-screen flex">
        <div className="wrap wrapWidth flex text-white">
          <div className="page-left flex flex-col">
            <div className="page-header flex items-center justify-between">
              <div className="page-title">Deposit </div>
              <div className="question-icon flex items-center justify-center bg-themeColor2">
                <QuestionIcon />
              </div>
            </div>
            <div className="coin-selection flex flex-col">

              <div className="selection-lbl">Select Plan :</div>
              <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                <div className="category flex aic bg-themeColor2">
                  <div
                    className="cbox cleanbtn flex aic rel pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setHide1(!hide1);
                    }}
                  >
                    <div className="slt flex aic">
                      <div className="selected-token flex items-center justify-center bg-themeColor1">
                        <div className="icon flex items-center justify-center">
                          {/* <img
                            // src={token1 ? token1.img : "/images/btc.png"}
                            className="img"
                          /> */}
                          <div className="token-name">
                            {plan ? plan?.name?.toUpperCase() : ""}
                          </div>
                        </div>
                      </div>
                      <div className="unit-name flex aic font s14 b4">
                        <span
                          className="unit-eng flex aic font s14 b4"
                          placeholder="Plano"
                        >
                          EARN  {plan ? plan.roi : ""}% {plan ? plan.rewardSchedule?.toString().toUpperCase() : ""} FOR {plan ? plan?.duration : ""} {plan?.rewardSchedule == "weekly" ? "WEEKS" : plan?.duration == "daily" ? "DAYS" : plan?.duration == "yearly" ? "years" : ""}
                        </span>
                      </div>
                    </div>

                    <div className="arrow-icon flex items-center justify-center">
                      <ArrowDownIcon />
                    </div>
                  </div>
                </div>
                <div className={`block flex aic abs ${hide1 ? "show" : ""}`}>
                  <div className="manue flex aic col anim">
                    {plans?.map((item, index) => (
                      <div
                        key={index}
                        className="slt flex aic"
                        onClick={(e) => {
                          setHide1(!hide1);
                          // setToken1(item);
                          setPlan(item);

                        }}
                      >
                        <div className="unit-name flex aic font s14 b4">
                          <span className="unit-eng flex aic font s14 b4">
                            {item?.name?.toUpperCase()} - EARN  {item ? item.roi : ""}% {item ? item.rewardSchedule?.toString().toUpperCase() : ""} FOR {item ? item?.duration : ""} {item?.rewardSchedule == "weekly" ? "WEEKS" : item?.rewardSchedule == "daily" ? "DAYS" : item?.rewardSchedule == "yearly" ? "years" : ""}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="btm-values flex items-center">
                <div className="lbl"></div>
                <div className="val"></div>
              </div>
            </div>





            <div className="coin-selection flex flex-col">
              <div className="sec-title">Preferred Payment</div>
              <div className="selection-lbl">Coin :</div>
              <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                <div className="category flex aic bg-themeColor2">
                  <div
                    className="cbox cleanbtn flex aic rel pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setHide2(!hide2);
                    }}
                  >
                    <div className="slt flex aic">
                      <div className="selected-token flex items-center justify-center bg-themeColor1">
                        <div className="icon flex items-center justify-center">
                          <img
                            // src={`/images/${token1?.currency}.png`}
                            src={`https://nowpayments.io/images/coins/${token1?.currency}.svg`}
                            className="img"
                          />
                          <div className="token-name">
                            {token1?.name}
                          </div>
                        </div>
                      </div>
                      <div className="unit-name flex aic font s14 b4">
                        <span
                          className="unit-eng flex aic font s14 b4"
                          placeholder="Plano"
                        >
                          {token1 ? token1?.name : ""}
                        </span>
                      </div>
                    </div>

                    <div className="arrow-icon flex items-center justify-center">
                      <ArrowDownIcon />
                    </div>
                  </div>
                </div>
                <div className={`block flex aic abs ${hide2 ? "show" : ""}`}>
                  <div className="manue flex aic col anim">
                    {balanceArray.map((item, index) => (
                      <div
                        key={index}
                        className="slt flex aic"
                        onClick={(e) => {
                          setHide2(!hide2);
                          setToken1(item);
                        }}
                      >
                        <div className="unit-name flex aic font s14 b4">
                          <span className="unit-eng flex aic font s14 b4">
                            {item.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="btm-values flex items-center">
                {/* <div className="lbl">Asset price:</div>
                <div className="val">$24,000/BTC</div> */}
              </div>
            </div>
            <div className="field-sec flex flex-col w-full">
              <div className="field-hdr flex items-center justify-between">
                <div className="hdr-lbl">Input Amount:</div>
                <div className="btm-values flex items-center">
                  <div className="lbl">Min/Max: </div>
                  {/* <div className="val">${plan?.min} / ${Number(plan?.max).toLocaleString('en-US', { maximumSignificantDigits: 3, notation: "compact" })}</div> */}
                  <div className="val">${plan?.min} / ${Number(plan?.max)}</div>
                </div>
              </div>
              <div className="input-field flex items-center bg-themeColor2">
                <div className="icon flex items-center justify-center">
                  <DollerIcon />
                </div>
                <input
                  type="text"
                  placeholder="Input Amount"
                  className="txt cleanbtn w-full"
                  onChange={(e) => {
                    setAmount(Number(e.target.value));
                  }}
                  value={amount}
                />
                <div className="inc-actions flex items-center">
                  <button
                    onClick={() => {
                      if (amount > 100) {
                        setAmount(amount - 100);
                      }
                    }}
                    className="inc-btn button">-</button>
                  <button
                    onClick={() => setAmount(amount + 100)}
                    className="inc-btn button">+</button>

                </div>
              </div>
            </div>
            <div className="action-section flex items-center justify-between">
              <div className="pin-side flex flex-col">
                <div className="btm-values flex items-center mb-4">
                  <div className="lbl">Daily Returns:</div>
                  <div className="val">{plan ? (plan.roi * amount) / 100 : ""}</div>
                </div>
                <div className="btm-values flex items-center">
                  <div className="lbl">Total Returns:</div>
                  <div className="val">{plan ? (plan.roi * amount) / 100 * plan.duration : ""}</div>
                </div>
              </div>
              <button
                onClick={initiateDeposit}
                className="btn-continue button flex items-center justify-center">
                <div className="btn-lbl flex-1 text-center">
                  Initiate Deposit
                </div>
                <div className="btn-icon flex items-center justify-center">
                  <ArrowNextIcon />
                </div>
              </button>
            </div>
            <Tips />
          </div>
          <div className="page-right flex flex-col justify-between">
            <div className="right-top flex flex-col">
              <div className="sec-title flex items-center justify-between">
                <div className="tag">Profit Calculator</div>
                <div className="icon flex items-center justify-center">
                  <CalculatorIcon />
                </div>
              </div>
              <div className="exchange-sec flex flex-col relative">
                <div className="exchange-box justify-between">
                  <div className="up flex items-center justify-between">
                    <div className="up-lbl">I Invest:</div>
                  </div>
                  <div className="btm flex items-center justify-between">
                    <div className="icon flex items-center justify-center">
                      <img src={"https://cdn-icons-png.flaticon.com/128/929/929156.png"} className="img" />
                    </div>
                    <input
                      type="text"
                      value={value}
                      placeholder="0.00"
                      onChange={(e) => setValue(e.target.value)}
                      className="btm-val input-txt cleanbtn"
                    />
                  </div>
                </div>
                <div className="exchange-box justify-between">
                  <div className="up flex items-center justify-between">
                    <div className="up-lbl">I Get:</div>
                  </div>
                  <div className="btm flex items-center justify-between">
                    <div className="icon flex items-center justify-center">
                      <img src={"https://cdn-icons-png.flaticon.com/128/929/929156.png"} className="img" />
                    </div>
                    <input
                      type="text"
                      disabled
                      value={`${plan ? ((plan?.roi * value / 100) * plan?.duration).toFixed(1) : 0}`}
                      placeholder="0.00"
                      className="btm-val input-txt cleanbtn"
                    />
                  </div>
                </div>
                <div className="exh-btn flex items-center justify-center absolute">
                  <ExchangeIcon />
                </div>
              </div>
            </div>
            {/* <div className="description-sec flex items-start flex-col">
              <div className="warn-icon flex items-center justify-center">
                <WarningIcon />
              </div>
              <h1 className="sec-tag">Send Only BTC to this Address !</h1>
              <p className="desc">
                Your payment option is BTC, please send Only BTC to the address
                on the next page to avoid lost of funds
              </p>
            </div> */}
            <Tips />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Deposit1;
