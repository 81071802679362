import React from "react";

function HandIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="39"
      fill="none"
      viewBox="0 0 50 39"
    >
      <path
        fill="#fff"
        d="M33.96 0h-6.71c-.625 0-1.227.3-1.688.84l-7.68 9c-.007.01-.015.03-.023.04-1.297 1.56-1.273 4.05-.164 5.6.992 1.39 3.078 1.76 4.383.27.008-.01.024-.01.031-.02l6.243-7.32c.507-.59 1.304-.55 1.765.1.469.65.43 1.66-.078 2.26L28 13.16l11.375 11.82c.227.24.43.5.617.77V6.4L35.727.94C35.266.34 34.625 0 33.96 0zm8.54 6.42v22.39c0 1.77 1.117 3.2 2.5 3.2h5V6.42h-7.5zm3.75 22.39c-.688 0-1.25-.72-1.25-1.6 0-.88.563-1.6 1.25-1.6.688 0 1.25.72 1.25 1.6 0 .88-.563 1.6-1.25 1.6zM0 32h5c1.383 0 2.5-1.43 2.5-3.2V6.42H0V32zm3.75-6.39c.688 0 1.25.72 1.25 1.6 0 .88-.563 1.6-1.25 1.6-.688 0-1.25-.72-1.25-1.6 0-.89.563-1.6 1.25-1.6zm34.055 1.86L26.14 15.35l-2.344 2.75c-2.32 2.71-5.875 2.45-7.945-.44-2.102-2.94-1.938-7.49.343-10.17L22.585 0H16.04c-.664 0-1.297.34-1.766.94L10 6.4v22.39h1.43l7.07 8.19c2.14 2.23 5.29 1.81 7.031-.93l.016-.02 1.398 1.55c1.242 1.3 3.078 1.05 4.086-.54l2.453-3.86.422.44c1.07 1.11 2.649.91 3.516-.47l.742-1.17c.875-1.38.711-3.39-.36-4.51z"
      ></path>
    </svg>
  );
}

export default HandIcon;
