import React from "react";
import SingleSlide from "./SingleSlide";
import styles from "./Hero.module.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import {
  cryptoBanner,
  forexBanner,
  generalBanner,
} from "../../../images/images";

const Hero = () => {
  const data = [
    {
      img: cryptoBanner,
      sub: `TRADE WITH ${process.env.REACT_APP_NAME}`,
      desc: `Step into the world of crypto trading with ${process.env.REACT_APP_NAME} - explore our comprehensive range of products and services to take charge of your investments.`,
      title: "Introducing a revolutionary crypto trading platform",
    },
    {
      img: forexBanner,
      title:
        "With top-tier Forex pairs, trade like the big players and get access to markets with unmatched liquidity.",
      desc: `Whether you want to trade traditional or exotic pairs, find them all at ${process.env.REACT_APP_NAME}.`,
      sub: `TRADE WITH ${process.env.REACT_APP_NAME}`,
    },
    {
      img: generalBanner,
      title: "Level up your trading with the world's most powerful trading platform",
      desc: `With a user base of over 500,000 traders, ${process.env.REACT_APP_NAME}'s competitive spreads and low commissions continue to showcase why we are the preferred choice for traders just like you.`,
      sub: `TRADE WITH ${process.env.REACT_APP_NAME}`,
    },
  ];
  const steps = [
    {
      title: "Register",
      text: "Sign up via our online portal and verify your account",
    },
    { title: "Deposit", text: "Fund your account with ease" },
    {
      title: "Start Trading",
      text: `Open your position and get on the trading floor with ${process.env.REACT_APP_NAME}`,
      to: "/login"
    },
  ];
  return (
    <section
      style={{
        background:
          "linear-gradient(253deg, rgb(1, 17, 80), rgb(1, 17, 80), rgb(1, 14, 34))",
      }}
      className="wrapper"
    >
      <div className={`${styles.hero}`}>
        <div className={styles.sliderDiv}>
          <Splide
            className="hero__slider"
            options={{
              type: "fade",
              perMove: 1,
              perPage: 1,
              arrows: true,
              pagination: false,
              drag: true,
              width: "100%",
            }}
          >
            {data.map((el, i) => (
              <SplideSlide key={i}>
                <SingleSlide {...el} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className={styles.stepWrapper}>
          {steps.map((el, i) => (
            <div className={styles.step} key={i}>
              <div className={styles.dot}></div>
              <h4 className="nunito">
                {i + 1}. {el.title}
              </h4>
              <p>{el.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
