import { createSlice } from "@reduxjs/toolkit";
const globalReducer = createSlice({
  name: "global",
  initialState: {
    success: "",
    searchBar: false,
    openSidebar: true,
    openSideMenu: false,
  },
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setOpenSidebar: (state, action) => {
      state.openSidebar = action.payload;
    },
    setOpenSideMenu: (state, action) => {
      state.openSideMenu = action.payload;
    },
    clearMessage: (state) => {
      state.success = "";
    },
    toggleSearchBar: (state) => {
      state.searchBar = !state.searchBar;
    },
  },
});
export const {
  setSuccess,
  clearMessage,
  toggleSearchBar,
  openSidebar,
  setOpenSidebar,
  setOpenSideMenu,
} = globalReducer.actions;
export default globalReducer.reducer;
