import React from "react";

function ReceiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g fill="#0CAF60" clipPath="url(#clip0_330_2478)">
        <path d="M3.334 15.833h13.333v-5a.833.833 0 011.667 0v5.834a.833.833 0 01-.834.833h-15a.833.833 0 01-.833-.833v-5.834a.833.833 0 011.667 0v5z"></path>
        <path d="M10.643 10.742l2.97-3.129a.66.66 0 11.956.908l-3.777 3.978a1.092 1.092 0 01-1.584 0L5.431 8.521a.66.66 0 01.956-.908l2.97 3.128V3.144a.643.643 0 011.286 0v7.599z"></path>
      </g>
      <defs>
        <clipPath id="clip0_330_2478">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReceiveIcon;
