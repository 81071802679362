import React from "react";
import HeaderLoginPage from "../components/Header/HeaderLoginPage";

const LandingWrapper = ({ children }) => {
  return (
    <div className={`flex flex-col bg-themeColor1 min-h-screen`}>
      <div className="flex flex-col h-full">
        <HeaderLoginPage />
        <section>{children}</section>
      </div>
    </div>
  );
};
export default LandingWrapper;
