import React, { useState, useEffect } from "react";
import Chart from "../../components/TradeChart/Chart.jsx";
import {
  CustomAreaChart,
  DonutHalfChart,
  DonutBitcoinChart,
  DonutBitcoinChart2,
  DonutBitcoinChart3,
  DonutBitcoinChart4,
  PriceChart,
} from "../../components/Charts";
import {
  ArrowDownIcon,
  ArrowNextIcon,
  SearchIcon,
  LinesIcon,
  DoubleIcons,
  SettingIcon,
  HistoryIcon,
  ExchangeIcon,
} from "../../assets/icons";
import Wrapper from "../../layouts/Wrapper";
import BalanceChart2 from "../../components/Charts/BalanceChart2";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "js-cookie";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import { toast } from "react-toastify";

const Main = ({ setBalance, setBalanceArray }) => {
  let token = Cookies.get("token");
  if (!token) {
    window.location.href = "/";
  }
  let clientid = process.env.REACT_APP_CLIENT_ID;
  const [userData, setUserData] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [balanceList, setBalanceList] = useState([]);

  const getUserBalance = async () => {
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          clientid: clientid,
          "x-access-token": token,
        },
      });
      if (data.error == "Invalid token") {
        toast.error("Session Expired", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "sessionExpired",
        });
        toast.loading("Redirecting to login page", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "sessionExpidred",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
      let refcount = 0;
      if (data?.data?.levelone?.length > 0) {
        refcount = data?.data.levelone.length;
      }

      setUserData(data?.data);
      setBalance(Number(data?.data?.balance).toFixed(2));
      setTransactions(data?.txn);
      setBalanceList(data?.balances);
      setBalanceArray(data?.balances);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("firstName", data.data.firstName);
      localStorage.setItem("lastName", data.data.lastName);
      localStorage.setItem("refearn", data.data.affiliateEarnings);
      localStorage.setItem('refcount', refcount);

    } catch (e) {
    }
  };
  React.useEffect(() => {
    getUserBalance();
  }, []);

  const customStyles = {
    control: (base, state) => ({
      ...base,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#0CAF60" : isSelected ? "#0CAF60" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      marginTop: 8,
      fontSize: "14px",
      fontWeight: 400,
      background: "#12181F",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "14px",
      fontWeight: 400,
    }),
  };
  const [time, setTime] = useState();
  const [days, setDays] = useState();
  const [months, setMonths] = useState();

  const [activeTimeTab, setActiveTimeTab] = useState("All");
  const shortTimeList = [
    { value: "1", label: "1h" },
    { value: "12", label: "12h" },
    { value: "48", label: "48h" },
  ];
  const timesList = [
    { lbl: "All" },
    { lbl: "1M" },
    { lbl: "6M" },
    { lbl: "1Y" },
    { lbl: "YTD" },
  ];
  const daysList = [
    { value: "7", label: "Last 07 Days" },
    { value: "15", label: "Last 15 Days" },
    { value: "30", label: "Last 30 Days" },
    { value: "60", label: "Last 60 Days" },
    { value: "90", label: "Last 90 Days" },
  ];
  const monthsList = [
    { value: "jan2022", label: "Jan 2022" },
    { value: "feb2022", label: "Feb 2022" },
    { value: "march2022", label: "Mar 2022" },
    { value: "apr2022", label: "Apr 2022" },
    { value: "may2022", label: "May 2022" },
    { value: "jun2022", label: "Jun 2022" },
    { value: "jul2022", label: "Jul 2022" },
    { value: "aug2022", label: "Aug 2022" },
    { value: "sep2022", label: "Sep 2022" },
    { value: "oct2022", label: "Oct 2022" },
    { value: "nov2022", label: "Nov 2022" },
    { value: "dec2022", label: "Dec 2022" },
  ];

  const TransactionsData = [
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      id: "#14525156",
      date: "Mar 21, 2022",
      status: "Complited",
      fees: "0.12000 BTC",
    },
  ];
  const [token1, setToken1] = useState({ img: "/images/btc.png", lbl: "BTC" });
  const [token2, setToken2] = useState({
    img: "/images/usdt.png",
    lbl: "USDT",
  });
  const [hide1, setHide1] = useState(false);
  const [hide2, setHide2] = useState(false);
  const tokensList = [
    { img: "/images/btc.png", lbl: "BTC" },
    { img: "/images/usdt.png", lbl: "USDT" },
    { img: "/images/trx.png", lbl: "BUSD" },
    { img: "/images/btcoin.png", lbl: "BTC" },
  ];

  const data = [{ name: userData?.balance?.toFixed(2) + " USD", value: 400 }];

  // const balanceList = [
  //   {
  //     img: "/images/usdttrc20.png",
  //     name: "USDT",
  //     currency: "usdttrc20",
  //     balance: 10210.8,
  //   },
  //   { img: "/images/btc.png", name: "BTC", currency: "btc", balance: 10210.8 },
  //   { img: "/images/trx.png", name: "LTC", currency: "ltc", balance: 10210.8 },
  //   {
  //     img: "/images/btc2.png",
  //     name: "ETH",
  //     currency: "eth",
  //     balance: 10210.8,
  //   },
  // ];

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide1(false);
      setHide2(false);
    });
  }, []);

  return (
    <Wrapper>
      <div className="lading-page h-screen flex">
        <div className="wrap wrapWidth flex flex-col text-white">
          <div className="page-top-sec flex">
            <div className="top-sec-left flex flex-col">
              <div className="finance-grids">
                <div className="price-box flex flex-col">
                  <div className="amount">
                    {userData?.balance?.toFixed(2)} USD
                  </div>
                  <div className="title-box flex items-center">
                    <div className="dot-box flex items-center justify-center bg-themeColor">
                      <div className="dot" />
                    </div>
                    <div className="lbl">Total Balance</div>
                  </div>
                  <div className="border-box relative bg-themeColor2 overflow-hidden">
                    <div className="border-line flex h-full bg-themeColor w-1/2" />
                  </div>
                </div>
                <div className="price-box flex flex-col">
                  <div className="amount">{userData?.investmentReturns?.toFixed(2)}USD</div>
                  <div className="title-box flex items-center">
                    <div className="dot-box flex items-center justify-center bg-green2">
                      <div className="dot" />
                    </div>
                    <div className="lbl">Investment Returns</div>
                  </div>
                  <div className="border-box relative bg-themeColor2 overflow-hidden">
                    <div className="border-line flex h-full bg-green2 w-1/2" />
                  </div>
                </div>
                <div className="price-box flex flex-col">
                  <div className="amount">
                    {userData?.affiliateEarnings?.toFixed(2)} USD
                  </div>
                  <div className="title-box flex items-center">
                    <div className="dot-box flex items-center justify-center bg-orange2">
                      <div className="dot" />
                    </div>
                    <div className="lbl">Affiliate Earnings</div>
                  </div>
                  <div className="border-box relative bg-themeColor2 overflow-hidden">
                    <div className="border-line flex h-full bg-orange2 w-1/2" />
                  </div>
                </div>
                {/* <div className="price-box flex flex-col">
                  <div className="amount">{userData?.teamEarnings.toFixed(2)} USD</div>
                  <div className="title-box flex items-center">
                    <div className="dot-box flex items-center justify-center bg-darkPink">
                      <div className="dot" />
                    </div>
                    <div className="lbl">Team Earnings</div>
                  </div>
                  <div className="border-box relative bg-themeColor2 overflow-hidden">
                    <div className="border-line flex h-full bg-darkPink w-1/2" />
                  </div>
                </div> */}
              </div>
              <div className="profile-stats-section flex flex-col">
                <div className="ps-header flex items-center justify-between">
                  <div className="hdr-title"></div>
                  <div className="times-tabs flex items-center">
                    {timesList.map((item, index) => (
                      <div
                        key={index}
                        className={`tab flex items-center justify-center cursor-pointer ${activeTimeTab === item.lbl ? "active" : ""
                          }`}
                        onClick={(e) => setActiveTimeTab(item.lbl)}
                      >
                        {item.lbl}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="area-chart-sec flex w-full overflow-hidden">
                  <CustomAreaChart />
                </div>
              </div>
            </div>
            <div className="top-sec-right flex flex-col overflow-hidden min-w-[325px]">
              <div className="sec-title">balance</div>

              <div className="btc-chars-sec flex flex-col justify-center">
                <div className="btc-full-chart flex items-center justify-center">
                  <div className="donut-chat-sec flex items-center justify-center relative">
                    <BalanceChart2 data={data} />
                  </div>
                  {/* <div className="donut-chat-sec flex items-center justify-center relative">
                    <DonutHalfChart />
                    <div className="chart-inner-val flex flex-col items-center justify-center absolute">
                      <div className="price">$69300</div>
                      <div className="val">0.0140 BTC</div>
                    </div>
                  </div> */}
                </div>
                <div className="separates-btc-charts">
                  <div className="wishlist-sec flex flex-col">
                    {balanceList.map((item, index) => (
                      <div className="card-item flex items-center bg-themeColor1">
                        <div className="card-left flex items-center">
                          <div className="icon flex items-center justify-center bg-themeColor2">
                            <img
                              src={`https://nowpayments.io/images/coins/${item?.currency}.svg`}
                              className="img"
                            />
                          </div>
                          <div className="about-token flex flex-col">
                            <div className="name">{item.name}</div>
                            <div className="currency">{item.currency}</div>
                          </div>
                        </div>
                        <div className="card-right flex items-start">
                          <div className="amountn">$ {item?.balance?.toFixed(2)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className="sec-title">Market Prices</div> */}
                  {/* <div className="wishlist-sec flex flex-col">
                    {balanceList.map((item, index) => (
                      <div className="card-item flex items-center bg-themeColor1">
                        <div className="card-left flex items-center">
                          <div className="icon flex items-center justify-center bg-themeColor2">
                            <img src={item.img} className="img" />
                          </div>
                          <div className="about-token flex flex-col">
                            <div className="name">{item.name}</div>
                            <div className="currency">{item.currency}</div>
                          </div>
                        </div>
                        <div className="card-right flex items-start">
                          <div className="amount">${item.balance}</div>
                        </div>
                      </div>
                    ))}
                  </div> */}
                  {/* <div className="single-btc-chart flex items-center justify-center">
                    <div className="s-chart">
                      <DonutBitcoinChart />
                    </div>
                    <div className="values flex flex-col">
                      <dvi className="lbl">USD</dvi>
                      <dvi className="price">
                        {userData?.balance?.toFixed(2)}
                      </dvi>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="see-all flex items-center justify-center">
                {/* <div className="lbl">See All</div>
                <div className="ico flex items-center justify-center">
                  <ArrowNextIcon />
                </div> */}
              </div>
            </div>
          </div>
          <div className="page-center-sec flex">
            <div className="center-sec-left flex flex-col">
              <div className="sc-header flex items-center justify-between">
                <div className="sec-title">Transaction History</div>
                {/* <Select
                  onChange={handleDays}
                  styles={customStyles}
                  options={daysList}
                  className="select"
                  name="days"
                  placeholder="Select Days"
                />
                <Select
                  onChange={handleMonths}
                  styles={customStyles}
                  options={monthsList}
                  className="select"
                  name="days"
                  placeholder="Select Days"
                /> */}
                {/* <div className="find-box flex items-center">
                  <div className="search-icon flex items-start justify-center">
                    <SearchIcon />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="txt cleanbtn w-full"
                  />
                  <div className="find-icon flex items-center justify-center bg-orange2">
                    <LinesIcon />
                  </div>
                </div> */}
              </div>
              <div className="transaction-table flex w-full">
                <div className="tran-tbl flex flex-col">
                  <div className="row flex w-full">
                    <div className="row-item flex items-center justify-start">
                      <div className="lbl"></div>
                      <div className="icon">
                        <DoubleIcons />
                      </div>
                    </div>
                    <div className="row-item flex items-center justify-start">
                      <div className="lbl">Transaction</div>
                      <div className="icon">
                        <DoubleIcons />
                      </div>
                    </div>
                    <div className="row-item flex items-center justify-start">
                      <div className="lbl">ID</div>
                      <div className="icon">
                        <DoubleIcons />
                      </div>
                    </div>
                    <div className="row-item flex items-center justify-start">
                      <div className="lbl">Date</div>
                      <div className="icon">
                        <DoubleIcons />
                      </div>
                    </div>
                    {/* <div className="row-item flex items-center justify-start">
                      <div className="lbl">Status</div>
                      <div className="icon">
                        <DoubleIcons />
                      </div>
                    </div> */}
                    <div className="row-item flex items-center justify-center">
                      <div className="lbl">IP</div>
                      <div className="icon">
                        <DoubleIcons />
                      </div>
                    </div>
                  </div>
                  {transactions.map((item, index) => (
                    <div className="row flex w-full">
                      <div className="row-item flex items-center justify-start">
                        <img
                          src={
                            item.type == "Withdrawal"
                              ? "https://cdn-icons-png.flaticon.com/128/3678/3678333.png"
                              : "https://cdn-icons-png.flaticon.com/128/9721/9721276.png"
                          }
                          className="coin-img"
                        />
                      </div>
                      <div className="row-item flex items-start justify-start flex-col">
                        <div className="amount"> {item.amount} USD</div>
                        <div className="type">{item.type}</div>
                      </div>
                      <div className="row-item flex items-center justify-start">
                        <div className="val">{item.payment_id}</div>
                      </div>
                      <div className="row-item flex items-center justify-start">
                        <div className="val">
                          {new Date(item.date).toLocaleString()}
                        </div>
                      </div>
                      {/* <div className="row-item flex items-center justify-start">
                        <div
                          className={`status-box flex items-center justify-center ${item.payment_status === "Declined"
                            ? "declined"
                            : item.payment_status === "Pending"
                              ? "pending"
                              : ""
                            }`}
                        >
                          {item.payment_status}
                        </div>
                      </div> */}
                      <div className="row-item flex items-center justify-center">
                        <div className="val">{item.ip ? item.ip : "internal"}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="center-sec-right flex flex-col">
              <div className="scr-header flex items-center justify-between">
                <div className="sec-title">Quick Exchage</div>
                <div className="icons flex items-center justify-center">
                  <div className="ico flex items-center justify-center mx-3">
                    <SettingIcon />
                  </div>
                  <div className="ico flex items-center justify-center">
                    <HistoryIcon />
                  </div>
                </div>
              </div>
              <div className="exchange-sec flex flex-col relative">
                <div className="exchange-box justify-between">
                  <div className="up flex items-center justify-between">
                    <div className="up-lbl">I have :</div>
                    <div className="up-lbl">0.12000 BTC</div>
                  </div>
                  <div className="btm flex items-center justify-between">
                    <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                      <div className="category flex aic">
                        <div
                          className="cbox cleanbtn flex aic rel pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setHide1(!hide1);
                          }}
                        >
                          <div className="slt flex aic">
                            <div className="icon flex items-center justify-center">
                              <img
                                src={token1 ? token1.img : "/images/btc.png"}
                                className="img"
                              />
                            </div>
                            <div className="unit-name flex aic font s14 b4">
                              <span
                                className="unit-eng flex aic font s14 b4"
                                placeholder="Plano"
                              >
                                {token1 ? token1.lbl : ""}
                              </span>
                            </div>
                          </div>

                          <div>
                            <ArrowDownIcon />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`block flex aic abs ${hide1 ? "show" : ""}`}
                      >
                        <div className="manue flex aic col anim">
                          {tokensList.map((item, index) => (
                            <div
                              key={index}
                              className="slt flex aic"
                              onClick={(e) => {
                                setHide1(!hide1);
                                setToken1(item);
                              }}
                            >
                              <div className="unit-name flex aic font s14 b4">
                                <span className="unit-eng flex aic font s14 b4">
                                  {item.lbl}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="0.00"
                      className="btm-val input-txt cleanbtn"
                    />
                  </div>
                </div>
                <div className="exchange-box justify-between">
                  <div className="up flex items-center justify-between">
                    <div className="up-lbl">I want :</div>
                    <div className="up-lbl">12000 USDT</div>
                  </div>
                  <div className="btm flex items-center justify-between">
                    <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                      <div className="category flex aic">
                        <div
                          className="cbox cleanbtn flex aic rel pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setHide2(!hide2);
                          }}
                        >
                          <div className="slt flex aic">
                            <div className="icon flex items-center justify-center">
                              <img
                                src={token2 ? token2.img : "/images/btc.png"}
                                className="img"
                              />
                            </div>
                            <div className="unit-name flex aic font s14 b4">
                              <span
                                className="unit-eng flex aic font s14 b4"
                                placeholder="Plano"
                              >
                                {token2 ? token2.lbl : ""}
                              </span>
                            </div>
                          </div>

                          <div>
                            <ArrowDownIcon />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`block flex aic abs ${hide2 ? "show" : ""}`}
                      >
                        <div className="manue flex aic col anim">
                          {tokensList.map((item, index) => (
                            <div
                              key={index}
                              className="slt flex aic"
                              onClick={(e) => {
                                setHide2(!hide2);
                                setToken2(item);
                              }}
                            >
                              <div className="unit-name flex aic font s14 b4">
                                <span className="unit-eng flex aic font s14 b4">
                                  {item.lbl}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="0.00"
                      className="btm-val input-txt cleanbtn"
                    />
                  </div>
                </div>
                <div className="exh-btn flex items-center justify-center absolute">
                  <ExchangeIcon />
                </div>
              </div>
              <button className="btn-continue button flex items-center justify-center">
                <div className="btn-lbl flex-1 text-center">Exchage</div>
                <div className="btn-icon flex items-center justify-center">
                  <ArrowNextIcon />
                </div>
              </button>
            </div> */}
          </div>
          {/* <div className="page-bottom-sec flex flex-col w-full">
            <div className="pbs-header flex items-center justify-between">
              <div className="sec-title">Market Prices </div>
            </div>
            <div className="photos-side flex flex-col">
              <div className="photos-swiper flex">
                <Swiper
                  slidesPerView={4}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    440: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    540: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    820: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    912: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  spaceBetween={20}
                  keyboard={{
                    enabled: true,
                  }}
                  navigation={true}
                  modules={[Keyboard, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="slider-card flex flex-col justify-between bg-themeColor1">
                      <div className="card-header flex items-center justify-between">
                        <div className="token-info flex items-center">
                          <img src="/images/btc.png" className="token-img" />
                          <div className="token-data flex flex-col">
                            <div className="token-name">Bitcoin</div>
                            <div className="token-sname">BTC</div>
                          </div>
                        </div>
                        <Select
                          onChange={handleShortTime}
                          styles={customStyles}
                          options={shortTimeList}
                          className="select"
                          name="time"
                          placeholder="24h"
                        />
                      </div>
                      <div className="chart">
                        <PriceChart />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slider-card flex flex-col justify-between bg-themeColor1">
                      <div className="card-header flex items-center justify-between">
                        <div className="token-info flex items-center">
                          <img src="/images/usdt.png" className="token-img" />
                          <div className="token-data flex flex-col">
                            <div className="token-name">Theter</div>
                            <div className="token-sname">USDT</div>
                          </div>
                        </div>
                        <Select
                          onChange={handleShortTime}
                          styles={customStyles}
                          options={shortTimeList}
                          className="select"
                          name="time"
                          placeholder="24h"
                        />
                      </div>
                      <div className="chart">
                        <PriceChart />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slider-card flex flex-col justify-between bg-themeColor1">
                      <div className="card-header flex items-center justify-between">
                        <div className="token-info flex items-center">
                          <img src="/images/trx.png" className="token-img" />
                          <div className="token-data flex flex-col">
                            <div className="token-name">UniSwap</div>
                            <div className="token-sname">BTC</div>
                          </div>
                        </div>
                        <Select
                          onChange={handleShortTime}
                          styles={customStyles}
                          options={shortTimeList}
                          className="select"
                          name="time"
                          placeholder="24h"
                        />
                      </div>
                      <div className="chart">
                        <PriceChart />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slider-card flex flex-col justify-between bg-themeColor1">
                      <div className="card-header flex items-center justify-between">
                        <div className="token-info flex items-center">
                          <img src="/images/btcoin.png" className="token-img" />
                          <div className="token-data flex flex-col">
                            <div className="token-name">Bitcoin</div>
                            <div className="token-sname">BTC</div>
                          </div>
                        </div>
                        <Select
                          onChange={handleShortTime}
                          styles={customStyles}
                          options={shortTimeList}
                          className="select"
                          name="time"
                          placeholder="24h"
                        />
                      </div>
                      <div className="chart">
                        <PriceChart />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slider-card flex flex-col justify-between bg-themeColor1">
                      <div className="card-header flex items-center justify-between">
                        <div className="token-info flex items-center">
                          <img src="/images/btc.png" className="token-img" />
                          <div className="token-data flex flex-col">
                            <div className="token-name">Bitcoin</div>
                            <div className="token-sname">BTC</div>
                          </div>
                        </div>
                        <Select
                          onChange={handleShortTime}
                          styles={customStyles}
                          options={shortTimeList}
                          className="select"
                          name="time"
                          placeholder="24h"
                        />
                      </div>
                      <div className="chart">
                        <PriceChart />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default Main;
