import React, { useState } from "react";
import Wrapper from "../../layouts/Wrapper";
import ReactCodeInput from "react-verification-code-input";
import { QuestionIcon, EyeOffIcon, ArrowNextIcon } from "../../assets/icons";
import Toggle from "../../components/Toggle";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const Settings = () => {


  let token = Cookies.get("token");
  if (!token) {
    window.location.href = "/";
  }
  let clientid = process.env.REACT_APP_CLIENT_ID;
  const getUserBalance = async () => {
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          clientid: clientid,
          "x-access-token": token,
        },
      });
      if (data.error == "Invalid token") {
        toast.error("Session Expired", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "sessionExpired",
        });
        toast.loading("Redirecting to login page", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "sessionExpidred",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
      let refcount = 0;
      if (data?.data?.levelone?.length > 0) {
        refcount = data?.data.levelone.length;
      }

      // setUserData(data?.data);
      // setBalance(Number(data?.data?.balance).toFixed(2));
      // setTransactions(data?.txn);
      // setBalanceList(data?.balances);
      // setBalanceArr(data?.balances);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("firstName", data.data.firstName);
      localStorage.setItem("lastName", data.data.lastName);
      localStorage.setItem("refearn", data.data.affiliateEarnings);
      localStorage.setItem('refcount', refcount);

    } catch (e) {
    }
  };
  React.useEffect(() => {
    getUserBalance();
  }, []);
  const [showPass, setShowPass] = useState(false);
  const [time, setTime] = useState("UTC");
  const [emailAuth, setEmailAuth] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);

  const [pin, setPin] = useState();
  const handleCode = (e) => {
    setPin(e);
  };
  return (
    <Wrapper>
      <div className="settings-page h-screen flex">
        <div className="wrap wrapWidth flex flex-col text-white">
          <div className="page-header flex items-center justify-between">
            <div className="page-title">Account Settings</div>
            <div className="question-icon flex items-center justify-center bg-themeColor2">
              <QuestionIcon />
            </div>
          </div>
          <div className="section-block flex flex-col">
            <div className="sec-tag">Contacts Details</div>
            <div className="row2">
              <div className="row">
                <div className="input-field flex items-center">
                  <div className="field-tag">Last Name:</div>
                  <div className="input-box flex items-center bg-themeColor2">
                    <input
                      disabled
                      type="text"
                      placeholder={localStorage.getItem("lastName")}
                      className="txt text-center cleanbtn w-full"
                      value={localStorage.getItem("lastName")}
                    />
                  </div>
                </div>
                <div className="input-field flex items-center">
                  <div className="field-tag">First Name:</div>
                  <div className="input-box flex items-center bg-themeColor2">
                    <input
                      disabled
                      type="text"
                      placeholder={localStorage.getItem("firstName")}
                      className="txt text-center cleanbtn w-full"
                    />
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="input-field flex items-center">
                  <div className="field-tag">Username:</div>
                  <div className="input-box flex items-center bg-themeColor2">
                    <input
                      disabled
                      type="text"
                      placeholder={localStorage.getItem("username")}
                      className="txt text-center cleanbtn w-full"
                    />
                  </div>
                </div>
                <div className="input-field flex items-center">
                  <div className="field-tag">Email:</div>
                  <div className="input-box flex items-center bg-themeColor2">
                    <input
                      disabled
                      type="email"
                      placeholder={localStorage.getItem("email")}
                      className="txt text-center cleanbtn w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-block flex flex-col">
            <div className="sec-tag mt-8">Security</div>
            <div className="row2">
              <div className="row">
                <div className="input-field flex items-center">
                  <div className="field-tag">
                    Current
                    <br />
                    Password:
                  </div>
                  <div className="input-box flex items-center bg-themeColor2">
                    <input
                      type={showPass ? "text" : "password"}
                      placeholder="Current Password"
                      className="txt text-center cleanbtn w-full"
                    />
                  </div>
                </div>
                <div className="input-field flex items-center">
                  <div className="field-tag">
                    New
                    <br />
                    Password:
                  </div>
                  <div className="input-box flex items-center bg-themeColor2">
                    <input
                      type={showPass ? "text" : "password"}
                      placeholder="New password"
                      className="txt text-center cleanbtn w-full"
                    />
                  </div>
                </div>
                <div className="input-field flex items-center">
                  <div className="field-tag">
                    Confirm
                    <br />
                    Password:
                  </div>
                  <div className="input-box flex items-center bg-themeColor2">
                    <input
                      type={showPass ? "text" : "password"}
                      placeholder="Confirm Password"
                      className="txt text-center cleanbtn w-full"
                    />
                  </div>
                </div>
                <div className="show-password-action flex items-center justify-center">
                  <div className="show-pass-lbl">Show/Hide Credentials:</div>
                  <div
                    className="icon flex items-center justify-center"
                    onClick={(e) => setShowPass(!showPass)}
                  >
                    <EyeOffIcon />
                  </div>
                </div>
              </div>
              <div className="qr-row flex flex-col">
                <div className="tabs-sec flex items-center">
                  {/* <div className="field-tag">Time Zone:</div>
                  <div className="tabs-box flex items-center justify-center">
                    <button
                      className={`btn-tab button ${time === "UTC" ? "active" : ""
                        }`}
                      onClick={(e) => setTime("UTC")}
                    >
                      UTC
                    </button>
                    <button
                      className={`btn-tab button ${time === "Local" ? "active" : ""
                        }`}
                      onClick={(e) => setTime("Local")}
                    >
                      Local
                    </button>
                  </div> */}
                </div>
                <div className="row2">
                  <div className="auths flex flex-col">
                    <div className="field-tag">2FA Auth.:</div>
                    <div className="auth-item flex items-center">
                      <div className="lbl">Email 2FA:</div>
                      <Toggle toggleOn={emailAuth} setToggleOn={setEmailAuth} />
                    </div>
                    {/* <div className="auth-item flex items-center">
                      <div className="lbl">Google 2FA:</div>
                      <Toggle
                        toggleOn={googleAuth}
                        setToggleOn={setGoogleAuth}
                      />
                    </div> */}
                  </div>
                  {/* <div className="qr-code-box flex justify-center">
                     <img src="/images/QR-Code-Scan.svg" className="qr-img" />
                   </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="section-block flex flex-col">
            <div className="sec-tag mt-8">Wallet</div>
            <div className="row">
              <div className="input-field flex items-center">
                <div className="field-tag">Bitcoin/BTC:</div>
                <div className="input-box flex items-center bg-themeColor2">
                  <input
                    type="text"
                    placeholder="Bitcoin Withdrawal address"
                    className="txt text-center cleanbtn w-full"
                  />
                </div>
              </div>
              <div className="input-field flex items-center">
                <div className="field-tag">BEP20/Address:</div>
                <div className="input-box flex items-center bg-themeColor2">
                  <input
                    type="text"
                    placeholder="Bep20 BNB,USDT,USDC Withdrawal address"
                    className="txt text-center cleanbtn w-full"
                  />
                </div>
              </div>
              <div className="input-field flex items-center">
                <div className="field-tag">TRC20/USDT:</div>
                <div className="input-box flex items-center bg-themeColor2">
                  <input
                    type="text"
                    placeholder="TRC20 USDT,USDC Withdrawal address"
                    className="txt text-center cleanbtn w-full"
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="action-section flex items-center justify-between">
            <div className="pin-side flex items-center">
              <div className="tag">Security Pin</div>
              <div className="access-code flex items-center">
                <ReactCodeInput
                  className="code-box"
                  fields={4}
                  onChange={handleCode}
                  type="password"
                />
              </div>
            </div>
            <button className="btn-continue button flex items-center justify-center">
              <div className="btn-lbl flex-1 text-center">Save Changes</div>
              <div className="btn-icon flex items-center justify-center">
                <ArrowNextIcon />
              </div>
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Settings;
