import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import {
  SearchIcon,
  LinesIcon,
  DoubleIcons,
  SendIcon,
  ReceiveIcon,
  LoadIcon,
} from "../../assets/icons";
import Wrapper from "../../layouts/Wrapper";
import Cookies from "js-cookie";

const MyInvestments = () => {
  const [investments, setInvestments] = useState([])
  let token = Cookies.get("token");
  let clientid = process.env.REACT_APP_CLIENT_ID
  const getUserBalance = async () => {
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          'clientid': clientid,
          'x-access-token': token
        }
      });

      setInvestments(data.investments)
    } catch (e) {
    }
  }
  React.useEffect(() => {
    getUserBalance()
  }, [])


  const [time, setTime] = useState();
  const [days, setDays] = useState();
  const [months, setMonths] = useState();

  const customStyles = {
    control: (base, state) => ({
      ...base,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#0CAF60" : isSelected ? "#0CAF60" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      marginTop: 8,
      fontSize: "14px",
      fontWeight: 400,
      background: "#12181F",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "14px",
      fontWeight: 400,
    }),
  };

  const shortTimeList = [
    { value: "1", label: "1h" },
    { value: "12", label: "12h" },
    { value: "48", label: "48h" },
  ];
  const timesList = [
    { lbl: "All" },
    { lbl: "1M" },
    { lbl: "6M" },
    { lbl: "1Y" },
    { lbl: "YTD" },
  ];
  const daysList = [
    { value: "7", label: "Last 07 Days" },
    { value: "15", label: "Last 15 Days" },
    { value: "30", label: "Last 30 Days" },
    { value: "60", label: "Last 60 Days" },
    { value: "90", label: "Last 90 Days" },
  ];
  const monthsList = [
    { value: "jan2022", label: "Jan 2022" },
    { value: "feb2022", label: "Feb 2022" },
    { value: "march2022", label: "Mar 2022" },
    { value: "apr2022", label: "Apr 2022" },
    { value: "may2022", label: "May 2022" },
    { value: "jun2022", label: "Jun 2022" },
    { value: "jul2022", label: "Jul 2022" },
    { value: "aug2022", label: "Aug 2022" },
    { value: "sep2022", label: "Sep 2022" },
    { value: "oct2022", label: "Oct 2022" },
    { value: "nov2022", label: "Nov 2022" },
    { value: "dec2022", label: "Dec 2022" },
  ];
  const handleDays = (e) => {
    setDays(e.value);
  };
  const handleShortTime = (e) => {
    setTime(e.value);
  };
  const handleMonths = (e) => {
    setMonths(e.value);
  };

  const TransactionsData = [
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btcoin.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/usdt.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/trx.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Canceled",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btcoin.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/usdt.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
  ];
  return (
    <Wrapper>
      <div className="transaction-history-page h-screen flex">
        <div className="wrap wrapWidth flex flex-col text-white">
          <div className="main-table-sec flex flex-col">
            <div className="sc-header flex items-center justify-between">
              <div className="sec-title">My Investments</div>
              <div className="filter-sec flex items-start justify-center">
                {/* <Select
                  onChange={handleDays}
                  styles={customStyles}
                  options={daysList}
                  className="select"
                  name="days"
                  placeholder="Select Days"
                />
                <Select
                  onChange={handleMonths}
                  styles={customStyles}
                  options={monthsList}
                  className="select"
                  name="days"
                  placeholder="Select Days"
                /> */}
                <div className="find-box flex items-center">
                  <div className="search-icon flex items-start justify-center">
                    <SearchIcon />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="txt cleanbtn w-full"
                  />
                  <div className="find-icon flex items-center justify-center bg-orange2">
                    <LinesIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="transaction-table flex w-full">
              <div className="tran-tbl flex flex-col">
                <div className="row flex w-full">
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl"></div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Transaction</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Rewards Paid</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Total Returns</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Last Payout</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Daily ROI</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Status</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-center">
                    <div className="lbl">Currency</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                </div>
                <div className="table-body flex flex-col w-full">
                  {investments?.map((item, index) => (
                    <div className="row flex w-full">
                      <div className="row-item flex items-center justify-start">
                        <img src={'https://cdn-icons-png.flaticon.com/128/600/600282.png'} className="coin-img" />
                      </div>
                      <div className="row-item flex items-start justify-start flex-col">
                        <div className="amount"> {item.amount} USD</div>
                        <div className="type">{item.payment_id}</div>
                      </div>
                      <div className="row-item flex items-center justify-start">
                        <div
                          className={`type-box flex items-center justify-center ${item.type === "Withdraw" ? "declined" : ""
                            }`}
                        >
                          <div className="type-icon flex items-center justify-center">
                            <ReceiveIcon />
                          </div>
                          <div className="type-lbl">{item?.rewardSchedule}</div>
                        </div>
                      </div>
                      <div className="row-item flex items-center justify-start">
                        <div className="val">{item?.returns ? item?.returns.toFixed(2) + " USD" : "No record"}</div>
                      </div>
                      <div className="row-item flex items-center justify-start">
                        <div className="val">{item.lastPaymentDate ? moment(item.lastPaymentDate).fromNow() : "No Record"}</div>
                      </div>
                      <div className="row-item flex items-center justify-start">
                        <div className="val">{item?.amount * item.roi / 100} USD</div>
                      </div>
                      <div className="row-item flex items-center justify-start">
                        <div
                          className={`status-box flex items-center justify-center ${item.status}`}
                        >
                          {item.status}
                        </div>
                      </div>
                      <div className="row-item flex items-center justify-center">
                        <div className="val">{item.pay_currency}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="table-footer flex items-center">
              <div className="load-more flex items-center justify-center">
                {/* <button className="btn-more button">
                  <div className="ico flex items-center justify-center text-center">
                    ...
                  </div>
                  <div className="btn-lbl">Load More</div>
                </button> */}
              </div>
              <div className="total-record flex items-center">
                <div
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="load-icon flex items-center justify-center hover:cursor-pointer">
                  <LoadIcon />
                </div>
                <div className="numb">
                  {investments.length ? investments.length : "0"}
                </div>
                <div className="lbl">Investments</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export const MyWithdrawals = () => {
  const [investments, setInvestments] = useState([])
  let token = Cookies.get("token");
  let clientid = process.env.REACT_APP_CLIENT_ID
  const getUserBalance = async () => {
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          'clientid': clientid,
          'x-access-token': token
        }
      });

      setInvestments(data.withdrawals)
    } catch (e) {
    }
  }
  React.useEffect(() => {
    getUserBalance()
  }, [])


  const [time, setTime] = useState();
  const [days, setDays] = useState();
  const [months, setMonths] = useState();

  const customStyles = {
    control: (base, state) => ({
      ...base,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#0CAF60" : isSelected ? "#0CAF60" : undefined,
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      marginTop: 8,
      fontSize: "14px",
      fontWeight: 400,
      background: "#12181F",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "14px",
      fontWeight: 400,
    }),
  };

  const shortTimeList = [
    { value: "1", label: "1h" },
    { value: "12", label: "12h" },
    { value: "48", label: "48h" },
  ];
  const timesList = [
    { lbl: "All" },
    { lbl: "1M" },
    { lbl: "6M" },
    { lbl: "1Y" },
    { lbl: "YTD" },
  ];
  const daysList = [
    { value: "7", label: "Last 07 Days" },
    { value: "15", label: "Last 15 Days" },
    { value: "30", label: "Last 30 Days" },
    { value: "60", label: "Last 60 Days" },
    { value: "90", label: "Last 90 Days" },
  ];
  const monthsList = [
    { value: "jan2022", label: "Jan 2022" },
    { value: "feb2022", label: "Feb 2022" },
    { value: "march2022", label: "Mar 2022" },
    { value: "apr2022", label: "Apr 2022" },
    { value: "may2022", label: "May 2022" },
    { value: "jun2022", label: "Jun 2022" },
    { value: "jul2022", label: "Jul 2022" },
    { value: "aug2022", label: "Aug 2022" },
    { value: "sep2022", label: "Sep 2022" },
    { value: "oct2022", label: "Oct 2022" },
    { value: "nov2022", label: "Nov 2022" },
    { value: "dec2022", label: "Dec 2022" },
  ];
  const handleDays = (e) => {
    setDays(e.value);
  };
  const handleShortTime = (e) => {
    setTime(e.value);
  };
  const handleMonths = (e) => {
    setMonths(e.value);
  };

  const TransactionsData = [
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btcoin.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/usdt.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/trx.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Canceled",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btcoin.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/btc.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Diposit",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
    {
      icon: "/images/usdt.png",
      transaction: { amount: "$659.10", type: "Withdraw USDT" },
      type: "Withdraw",
      id: "#14525156",
      date: "Mar 21, 2022 / 3:25:19",
      ip: "192.168.1.1",
      status: "Complited",
      fees: "0.12000 BTC",
    },
  ];
  return (
    <Wrapper>
      <div className="transaction-history-page h-screen flex">
        <div className="wrap wrapWidth flex flex-col text-white">
          <div className="main-table-sec flex flex-col">
            <div className="sc-header flex items-center justify-between">
              <div className="sec-title">My Withdrawals</div>
              <div className="filter-sec flex items-start justify-center">
                {/* <Select
                  onChange={handleDays}
                  styles={customStyles}
                  options={daysList}
                  className="select"
                  name="days"
                  placeholder="Select Days"
                />
                <Select
                  onChange={handleMonths}
                  styles={customStyles}
                  options={monthsList}
                  className="select"
                  name="days"
                  placeholder="Select Days"
                /> */}
                <div className="find-box flex items-center">
                  <div className="search-icon flex items-start justify-center">
                    <SearchIcon />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="txt cleanbtn w-full"
                  />
                  <div className="find-icon flex items-center justify-center bg-orange2">
                    <LinesIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="transaction-table flex w-full">
              <div className="tran-tbl flex flex-col">
                <div className="row flex w-full">
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl"></div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Transaction</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>

                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Wallet Address</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>


                  <div className="row-item flex items-center justify-start">
                    <div className="lbl">Status</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-center">
                    <div className="lbl">Currency</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                  <div className="row-item flex items-center justify-center">
                    <div className="lbl">Date</div>
                    <div className="icon">
                      <DoubleIcons />
                    </div>
                  </div>
                </div>
                <div className="table-body flex flex-col w-full">
                  {investments?.map((item, index) => (
                    <div className="row flex w-full">
                      <div className="row-item flex items-center justify-start">
                        <img src={'https://cdn-icons-png.flaticon.com/128/5501/5501360.png'} className="coin-img" />
                      </div>
                      <div className="row-item flex items-start justify-start flex-col">
                        <div className="amount"> {item.amount} USD</div>
                        <div className="type">{item.payment_id}</div>
                      </div>

                      <div className="row-item flex items-center justify-start">
                        <div className="val">{item?.payment_wallet.slice(0, 9) + "......." + item?.payment_wallet.slice(-8)}</div>
                      </div>


                      <div className="row-item flex items-center justify-start">
                        <div
                          className={`status-box flex items-center justify-center ${item?.payment_status?.toUpperCase()}`}
                        >
                          {item.payment_status}
                        </div>
                      </div>
                      <div className="row-item flex items-center justify-center">
                        <div className="val">{item.pay_currency}</div>
                      </div>
                      <div className="row-item flex items-center justify-center">
                        <div className="val">{new Date(item.date).toLocaleString()}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="table-footer flex items-center">
              <div className="load-more flex items-center justify-center">
                {/* <button className="btn-more button">
                  <div className="ico flex items-center justify-center text-center">
                    ...
                  </div>
                  <div className="btn-lbl">Load More</div>
                </button> */}
              </div>
              <div className="total-record flex items-center">
                <div
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="load-icon flex items-center justify-center hover:cursor-pointer">
                  <LoadIcon />
                </div>
                <div className="numb">
                  {investments.length ? investments.length : "0"}
                </div>
                <div className="lbl">Records</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MyInvestments;
