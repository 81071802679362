import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../store/reducers/authReducer";
import { DashboardIcon, ArrowDownIcon } from "../../assets/icons";

const HeaderLoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuList = [
    // { lbl: "Docs", url: "https://qudus-ai-labs.gitbook.io/qudaix-investment-brochure/intro/company-overview" },
    // { lbl: "Market", url: "/" },
    // { lbl: "NFT", url: "/" },
    // { lbl: "Promotion", url: "/" },
    // { lbl: "About", url: "/" },
    // { lbl: "Blog", url: "/" },
  ];
  const userLogin = () => {
    navigate("/dashboard/home");
  };
  return (
    <div className="login-page-header flex bg-blueDark">
      <div className="wrap wrapWidthHeader flex items-center">
        <div className="left-side flex items-center">
          <div className="logo">
            <Link to="/">
              <img width="55" src={process.env.REACT_APP_LOGO} className="logo-img" />
            </Link>
          </div>
          <div
            className="dashboard-icon flex items-center justify-center cursor-pointer"
            onClick={userLogin}
          >
            {/* <DashboardIcon /> */}
          </div>
          <div className="menu-list flex items-center">
            {menuList.map((item, index) => (
              <div
                onClick={() => window.open(item.url, "_blank")}
                className="menu-item">{item.lbl}</div>
            ))}
          </div>
        </div>
        <div className="right-side flex items-center justify-end">
          <div className="language-dropdown flex items-center justify-center bg-themeColor1">
            <div className="flag-icon flex items-center justify-center">
              <img src="/images/eth.svg" className="img" />
            </div>
            <div className="language-name">English</div>
            <div className="icon">
              <ArrowDownIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLoginPage;
