import React from "react";
import styles from "./Products.module.css";

const SingleProduct = ({ img, tite, para, btn }) => {
  return (
    <div className={styles.productCard}>
      <div className={styles.icoWrapp}>
        <img src={img} alt="" />
      </div>
      <h4 className="nunito">{tite}</h4>
      <p>{para}</p>
      <button
        onClick={
          () => {
            window.location.href = '/register';
          }

        }
        className={styles.ctaBtn}>{btn}</button>
    </div>
  );
};

export default SingleProduct;
