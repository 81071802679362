import React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";

const CustomAreaChart = () => {
  const data = [
    { name: "Mon", value: 20384 },
    { name: "Tue", value: 15929 },
    { name: "Wed", value: 9183 },
    { name: "Thu", value: 29382 },
    { name: "Fri", value: 14892 },
    { name: "Sat", value: 51938 },
    { name: "Sun", value: 39193 },
  ];

  const tooltipStyle = {
    background: "rgba(18, 24, 31, 0.53)",
    backdropFilter: "blur(8px)",
    borderRadius: "16px",
    border: "none",
    color: "#fff",
    fontSize: "14px",
    padding: "8px",
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        <CartesianGrid stroke="none" />
        <defs>
          <linearGradient id="chartBackground" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgba(12, 175, 96, 0.5)" />
            <stop offset="100%" stopColor="rgba(22, 29, 38, 0)" />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          fill="url(#chartBackground)" // Use the custom background color gradient
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
