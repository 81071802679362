import React from "react";
import { useEffect, useState } from "react";

import styles from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { logo } from "../../../images/images";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [headerToggle, setHeaderToggle] = useState(false);
  const [headerBg, setHeaderBg] = useState(false);
  useEffect(() => {
    const changeBg = () => {
      if (window.scrollY > 0) {
        setHeaderBg(true);
      } else {
        setHeaderBg(false);
      }
    };
    window.addEventListener("scroll", changeBg);
    return () => {
      window.removeEventListener("scroll", changeBg);
    };
  }, []);

  return (
    <header className="wrapper">
      <div className={`${headerBg ? styles.newBg : ""} ${styles.contain}`}>
        <div className={styles.topNav}>
          <div className={styles.leftNav}>
            <img width="70" src={process.env.REACT_APP_LOGO} alt="" />
            <nav>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? [styles.navItem, styles.activeLink, "nunito"].join(" ")
                    : [styles.navItem, "nunito"].join(" ")
                }
                to="/"
              >
                Home
              </NavLink>
              <NavLink
                to="/brochure"
                className={({ isActive }) =>
                  isActive
                    ? [styles.navItem, styles.activeLink, "nunito"].join(" ")
                    : [styles.navItem, "nunito"].join(" ")
                }
              >
                Brochure
              </NavLink>
            </nav>
          </div>
          <div className={styles.rightNav}>
            <button
              onClick={() => window.location.href = "/login"}
              className={styles.ctaBtn}>Login</button>
            <button
              onClick={() => window.location.href = "/register"}
              className={styles.ctaInverBtn}>Register</button>
          </div>
          <button
            onClick={() => setHeaderToggle((prev) => !prev)}
            className={styles.menuToggler}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div
          style={{
            maxHeight: headerToggle ? "2000px" : "0px",
            padding: headerToggle ? "25px 0px" : "0px",
          }}
          className={styles.mobNav}
        >
          <NavLink
            className={({ isActive }) =>
              isActive
                ? [styles.navItem, styles.activeLink, "nunito"].join(" ")
                : [styles.navItem, "nunito"].join(" ")
            }
            to="/"
          >
            Home
          </NavLink>
          <NavLink
            to="/brochure"
            className={({ isActive }) =>
              isActive
                ? [styles.navItem, styles.activeLink, "nunito"].join(" ")
                : [styles.navItem, "nunito"].join(" ")
            }
          >
            Brochure
          </NavLink>
          <div className={styles.mobBtn}>
            <button
              onClick={() => window.location.href = "/login"}
              className={styles.ctaBtn}>Login</button>
            <button
              onClick={() => window.location.href = "/register"}
              className={styles.ctaInverBtn}>Register</button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
