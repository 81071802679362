import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenSidebar,
  setOpenSideMenu,
} from "../../store/reducers/globalReducer";
import { logout } from "../../store/reducers/authReducer";
import {
  VerifiedCheckIcon,
  HomeIcon,
  ExchangeIcon,
  PriceIcon,
  LockIcon,
  WalletIcon,
  MarketIcon,
  TransactionIcon,
  SettingIcon,
  NewsIcon,
  ChatIcon,
  LightIcon,
  DarkIcon,
  SendIcon,
  ArrowDownIcon,
  ReceiveIcon,
} from "../../assets/icons";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const Sidebar = () => {
  const navigate = useNavigate();
  const { openSidebar, openSideMenu } = useSelector(
    (state) => state.globalReducer
  );
  const [mode, setMode] = useState("dark");
  const dispatch = useDispatch();
  const navBarItems = [
    { lbl: "Dashboard", icon: <HomeIcon />, slug: "/dashboard/home" },
    {
      lbl: "Deposit",
      icon: <ExchangeIcon />,
      slug: "/dashboard/deposit",
    },
    { lbl: "Withdraw", icon: <PriceIcon />, slug: "/dashboard/withdraw" },
    // {
    //   lbl: "Wallet",
    //   icon: <WalletIcon />,
    //   slug: "/",
    // },
    // {
    //   lbl: "NFT Market",
    //   icon: <MarketIcon />,
    //   slug: "/",
    // },
    {
      lbl: "My Investments",
      icon: <TransactionIcon />,
      slug: "/dashboard/myinvestments",
    },
    {
      lbl: "My Withdrawals",
      icon: <TransactionIcon />,
      slug: "/dashboard/mywithdrawals",
    },
    {
      lbl: "Affiliate",
      icon: <NewsIcon />,
      slug: "/dashboard/affiliate",
    },
    {
      lbl: "Settings",
      icon: <SettingIcon />,
      slug: "/dashboard/setting",
    },

  ];

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      setOpenSidebar(false);
      dispatch(setOpenSideMenu(false));
    });
  }, []);
  const userLogout = () => {
    dispatch(logout("userToken"));
    navigate("/");
  };

  const CommonData = () => {
    return (
      <>
        <div
          className={`hdr flex items-center justify-center ${openSidebar ? "" : "center"
            }`}
        >
          {openSidebar && <img width="55" src={process.env.REACT_APP_LOGO} className="logo-img" />}
        </div>
        <div className="user-info flex flex-col items-center justify-center">
          {/* <img src="/images/user.png" className="user-image" /> */}
          <div className="user-name">Welcome Back</div>
          <div className="user-status flex items-center justify-center">
            Regular{" "}
            <div className="check-icon flex items-center justify-center">
              <VerifiedCheckIcon />
            </div>
          </div>
        </div>
        <div className="items flex flex-col">
          <>
            {navBarItems.map((item, index) => (
              <NavLink
                to={item.slug}
                key={index}
                className="list-item flex"
                onClick={(e) => dispatch(setOpenSideMenu(false))}
              >
                <div className="ico flex items-center justify-center">
                  {item.icon}
                </div>
                {openSidebar && <div className="li">{item.lbl}</div>}
              </NavLink>
            ))}
          </>
          <div className="chat-sec flex flex-col">
            <div className="sec-title">Session</div>
            <NavLink to="/" className="list-item flex">
              <div className="ico flex items-center justify-center">
                <ReceiveIcon />
              </div>
              {openSidebar && <div
                onClick={() => {
                  toast.success("Logout Successfully");
                  // toast.loading('redirecting... to login page', {
                  //   toastId: 1,

                  // })
                  Cookies.remove("token");
                  window.location.reload();
                }}
                className="li">Logout</div>}
              {/* <div className="numb flex items-center justify-center">{""}</div> */}
            </NavLink>
          </div>
        </div>
        {/* <div className="sidebar-footer flex items-center justify-center">
          <div className="light-dark-mode flex items-center justify-center bg-themeColor1">
            <button
              className={`btn-mode button items-center justify-center ${mode === "light" ? "active" : ""
                }`}
              onClick={(e) => setMode("light")}
            >
              <div className="btn-icon flex items-center justify-center">
                <LightIcon />
              </div>
              <div className="btn-lbl">Light</div>
            </button>
            <button
              className={`btn-mode button items-center justify-center ${mode === "dark" ? "active" : ""
                }`}
              onClick={(e) => setMode("dark")}
            >
              <div className="btn-icon flex items-center justify-center">
                <DarkIcon />
              </div>
              <div className="btn-lbl">Dark</div>
            </button>
          </div>
        </div> */}
      </>
    );
  };

  return (
    <>
      <div
        className={`sidebar-s  fixed rel anim ${openSidebar ? "full-open" : ""
          }`}
      >
        <div
          className={`side-block bg-blueDark flex col anim ${openSidebar ? "full-open" : ""
            }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CommonData />
        </div>
      </div>
      <div
        className={`side-menu fixed rel anim ${openSideMenu ? "showMenu" : ""}`}
      >
        <div
          className={`side-block flex col anim ${openSideMenu ? "show" : "hide"
            }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CommonData />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
