import React from "react";

function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_204_658)">
        <path
          fill="#F0F4F7"
          d="M3.34 17a10.017 10.017 0 01-.979-2.326 3 3 0 00.002-5.347A9.99 9.99 0 014.864 4.99a3 3 0 004.631-2.674 9.99 9.99 0 015.007.002 3 3 0 004.632 2.672A9.99 9.99 0 0120.66 7c.433.749.757 1.53.978 2.326a3 3 0 00-.002 5.347 9.99 9.99 0 01-2.5 4.337 3 3 0 00-4.632 2.674 9.99 9.99 0 01-5.007-.002 3 3 0 00-4.632-2.672A10.016 10.016 0 013.34 17zm5.66.196a4.993 4.993 0 012.25 2.77c.498.047 1 .048 1.498 0A4.993 4.993 0 0115 17.197a4.993 4.993 0 013.525-.564c.29-.408.54-.843.748-1.298A4.993 4.993 0 0118 12c0-1.26.47-2.437 1.273-3.334-.21-.455-.46-.89-.75-1.298A4.993 4.993 0 0115 6.804a4.993 4.993 0 01-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 019 6.803a4.993 4.993 0 01-3.526.565 7.99 7.99 0 00-.748 1.298A4.993 4.993 0 016 12a4.99 4.99 0 01-1.273 3.334c.21.455.46.89.75 1.298A4.993 4.993 0 019 17.196zM12 15a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_204_658">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SettingIcon;
