import React from "react";

function ReferalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1_1939)">
        <path
          fill="#C4C4C4"
          d="M9.472 17.35a8 8 0 119.22 0l2.084 4.76a.5.5 0 01-.46.7H7.847a.5.5 0 01-.458-.7l2.083-4.76zm6.734-.694l1.332-.94a6 6 0 10-6.912 0l1.33.94-1.816 4.154h7.883l-1.817-4.154zm-6.005-4.876l1.94-.485a2 2 0 003.882 0l1.94.485a4.002 4.002 0 01-7.763 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_1939">
          <path
            fill="#fff"
            d="M0 0H23.535V24H0z"
            transform="translate(.016 .81)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReferalIcon;
