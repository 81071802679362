import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import "./css/App.scss";
import "./index.css";
import Routing from "./routes/Routing";



//
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  zora,
  bsc
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

function App() {
  const { chains, publicClient } = configureChains(
    [bsc],
    [
      alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
      publicProvider()
    ]
  );
  const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    projectId: 'd3873ed18b4ac26cf6f5dffdb2fe9cbd',
    chains
  });
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })


  return (
    <Fragment>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          <ToastContainer />
          {/* <div id="google_translate_element"></div> */}
          <Routing />;
        </RainbowKitProvider>
      </WagmiConfig>
    </Fragment>
  )


}

export default App;
