import React from "react";

import TitlePart from "../../TitlePart/TitlePart";
import styles from "./Account.module.css";
import SingleCard from "./SingleCard";
import axios from "axios";
import Cookies
  from "js-cookie";

const Account = () => {
  const [plan, setPlans] = React.useState([])
  const getPlans = async () => {
    let api = "https://yield-jet.sentrynode.io/"
    // let api = 'http://localhost:8080/'
    let url = api + "dashboard/plans"
    let token = Cookies.get("token");
    let clientid = process.env.REACT_APP_CLIENT_ID;
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        'clientid': clientid,
        'x-access-token': token
      }
    })

    setPlans(data?.data)
  }

  let num = plan?.length
  React.useEffect(() => {
    getPlans()
  }, [])
  const data = [
    {
      tier: "Basic",
      percent: "1.5% ",
      duration: "5 Days",
      max: "500",
      min: "$20,000",
    },
    {
      tier: "Silver",
      percent: "3% ",
      duration: "10 Days",
      min: "20,100",
      max: "Unlimited",
    },
    {
      recomended: true,
      tier: "Gold",
      percent: "5% ",
      duration: "20 Days",
      min: "50,000",
      max: "Unlimited",
    },
    {
      tier: "Platinum",
      percent: "10% ",
      duration: "4 Months",
      min: "100,000",
      max: "Unlimited",
    },
    {
      tier: "Diamond",
      percent: "15% ",
      duration: "8 Months",
      min: "300,000",
      max: "Unlimited",
    },
  ];
  return (
    <section className="wrapper">
      <div className={`${styles.account}`}>
        <TitlePart
          // title="We offer  different account types to serve our clients in the best way possible."
          //insert num variable in the title
          title={`We offer ${num} different account types to serve our clients in the best way possible.`}

          top="ACCOUNT TYPES"
        />
        <div className={styles.grid}>
          {plan?.map((el, i) => (
            <SingleCard {...el} key={i} />
          ))}
        </div>

        <button className={styles.ctaBtn}>Learn More</button>
      </div>
    </section>
  );
};

export default Account;
