import React from "react";

function ArrowNextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill="#fff"
        d="M1.42 8.64h9.654l-3.77 3.74a1.165 1.165 0 000 1.626 1.147 1.147 0 001.612 0l5.732-5.7a1.176 1.176 0 000-1.611L8.916.995a1.147 1.147 0 00-1.612 0 1.2 1.2 0 00-.334.8c.003.302.122.59.334.806l3.77 3.755H1.42a1.14 1.14 0 000 2.281v.003z"
      ></path>
    </svg>
  );
}

export default ArrowNextIcon;
