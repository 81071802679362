import React from "react";
import styles from "./Button.module.css";

const Button = ({ btnStyles, name, cta }) => {
  return cta ? (
    <button style={{ ...btnStyles }} className={styles.ctaBtn}>
      {name}
    </button>
  ) : (
    <button style={{ ...btnStyles }} className={styles.ctaInverBtn}>
      {name}
    </button>
  );
};

export default Button;
