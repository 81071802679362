import React from "react";
import Header from "../../components/Home/Header/Header";
import About from "../../components/Home/About/About";
import Account from "../../components/Home/Account/Account";
import Choose from "../../components/Home/Choose/Choose";
import Hero from "../../components/Home/Hero/Hero";
import Products from "../../components/Home/Products/Products";
import Testimonial from "../../components/Home/Testimonial/Testimonial";
import Footer from "../../components/Home/Footer/Footer";
import styles from "./Home.module.css";

const Home = () => {
  return (
    <div className={styles.mainContainer}>
      <Header />
      <Hero />
      <Products />
      <div
        style={{
          background:
            "linear-gradient(253deg, rgb(1, 17, 80), rgb(1, 17, 80), rgb(1, 14, 34))",
        }}
      >
        <About />
        <Choose />
      </div>
      <Account />
      {/* <Testimonial /> */}
      <Footer />
    </div>
  );
};

export default Home;
