import React from "react";

function PriceChart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="84"
      fill="none"
      viewBox="0 0 251 84"
    >
      <path
        fill="url(#paint0_linear_285_2442)"
        d="M2.276 71.398H1V84h249.239V49.789c-5.221 2.987-9.482-8.68-19.239-6.274-9.757 2.406-5.823 17.344-12.349 19.253-6.526 1.909-9.57 5.311-10.73 4.15-1.16-1.163-1.74-2.822-5.366-1.993-3.625.83-4.351-1.991-5.511-3.734-1.16-1.743-2.158-7.469-5.059-6.141-2.9 1.328-4.494-8.382-7.104-9.792-2.611-1.411-4.389 13.368-7.571 10.557-2.747-2.428-4.775.646-6.08-2.425-1.305-3.07-4.641-1.908-7.106-3.32-2.466-1.41-1.306-5.393-2.756-6.555-1.45-1.162-3.915.415-5.511 1.742-1.595 1.328-1.16.996-4.496 0-3.335-.995-8.121 5.395-10.586 6.225-2.466.83-1.451-2.407-3.336-3.403-1.885-.996-2.465 1.66-5.221.996-2.755-.664-4.351-7.054-5.511-8.548-1.16-1.494-1.595-1.494-4.931-.664-3.335.83-4.93-.58-5.656-2.074-.725-1.494-.58-1.66-5.365-1.328-4.786.332-5.656-1.909-6.962-4.564-1.305-2.656-6.236-1.577-8.846-1.91-2.61-.331-.29-2.572-1.45-3.485-1.16-.912-1.306 0-3.916 0-2.088 0-2.127-.663-1.885-.995-.194-11.12-1.13-18.617-2.174-23.729-1.305-6.39-.582 24.724-4.063 24.724-2.784 0-3.77 1.882-3.915 2.822.048 5.726.116 17.494 0 18.755-.145 1.577-1.015 2.158-5.511 2.573-4.496.415-4.786 4.315-8.267 5.809-3.48 1.494-3.335-3.735-4.205-5.228-.87-1.494-1.74.747-3.19 1.493-1.451.747-1.306-.166-3.626-1.493-2.32-1.328-9.282 10.207-10.297 11.535-1.015 1.328-2.175.996-4.35 0-2.176-.996-10.298 6.224-10.878 6.97-.58.748-2.175 0-4.35-.746-1.74-.598-2.563.249-2.756.747.193.553-.464 1.66-4.64 1.66-5.222 0-3.771.58-6.527 1.493-2.204.73-5.366-.691-6.671-1.493z"
      ></path>
      <path
        stroke="#0CAF60"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1.586 72.214h.59c1.304.802 4.158 2.264 6.362 1.534 2.755-.913 1.305-1.494 6.526-1.494 4.177 0 4.834-1.107 4.64-1.66.194-.498 1.016-1.344 2.756-.747 2.176.747 3.77 1.494 4.351.747.58-.747 8.702-7.966 10.877-6.97 2.175.995 3.335 1.327 4.35 0 1.016-1.329 7.977-12.864 10.297-11.536 2.32 1.328 2.176 2.24 3.626 1.494 1.45-.747 2.32-2.988 3.19-1.494.87 1.494.726 6.722 4.206 5.228 3.48-1.494 3.77-5.394 8.267-5.809 4.496-.415 5.366-.996 5.51-2.572.117-1.262.049-13.03 0-18.756.146-.94 1.132-2.821 3.916-2.821 3.48 0 2.758-31.115 4.063-24.725 1.044 5.112 1.98 12.609 2.173 23.729-.241.332-.203.996 1.886.996 2.61 0 2.755-.913 3.915 0 1.16.913-1.16 3.153 1.45 3.485 2.611.332 7.542-.747 8.847 1.91 1.305 2.655 2.176 4.895 6.961 4.563 4.786-.332 4.641-.166 5.366 1.328.725 1.494 2.321 2.905 5.656 2.075 3.336-.83 3.771-.83 4.931.664 1.16 1.493 2.756 7.883 5.511 8.547 2.755.664 3.336-1.991 5.221-.995 1.885.995.87 4.232 3.335 3.402 2.466-.83 7.252-7.22 10.587-6.224 3.336.996 2.901 1.328 4.496 0 1.595-1.328 4.061-2.905 5.511-1.743 1.45 1.162.29 5.146 2.755 6.556 2.466 1.411 5.801.25 7.107 3.32 1.305 3.07 3.333-.004 6.08 2.424 3.182 2.812 4.96-11.968 7.571-10.557 2.61 1.41 4.203 11.12 7.104 9.793 2.9-1.328 3.899 4.398 5.059 6.14 1.16 1.743 1.885 4.565 5.511 3.735 3.626-.83 4.206.83 5.366 1.992 1.16 1.162 4.204-2.241 10.73-4.15 6.526-1.908 3.501-16.846 13.258-19.253 9.757-2.406 13.279 8.617 18.5 5.63"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_285_2442"
          x1="125.925"
          x2="128.061"
          y1="-2.033"
          y2="80.635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12BE73"></stop>
          <stop offset="1" stopColor="#161D26" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PriceChart;
