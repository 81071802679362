import React from "react";
import styles from "./Account.module.css";

const SingleCard = ({ name, roi: percent, duration, min, max, rewardSchedule, recomended }) => {
  return (
    <div className={`${recomended ? styles.recomended : ""} ${styles.box}`}>
      <h4 className="nunito">{name}</h4>
      <h3 className="nunito">{percent} %</h3>
      <div className={styles.option}>
        <p>Duration: {duration} {rewardSchedule}</p>
        <img src="/check.svg" alt="" />
      </div>
      <div className={styles.option}>
        <p>Min: ${min}</p>
        <img src="/check.svg" alt="" />
      </div>
      <div className={styles.option}>
        <p>Max: {max == "0" ? "Unlimited" : "$" + max}</p>
        <img src="/check.svg" alt="" />
      </div>
      <div className={styles.option}>
        <p>Instant Withdrawal</p>
        <img src="/check.svg" alt="" />
      </div>
      <div className={styles.option}>
        <p>5-3-1 Referral Tree</p>
        <img src="/check.svg" alt="" />
      </div>
    </div>
  );
};
export default SingleCard;

