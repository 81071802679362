import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Private from "./Private";
import Public from "./Public";
import axios from "axios";
import Cookies from "js-cookie";

import { toast } from "react-toastify";
// Admin Pages
import Login from "../Pages/Login";
import { ResetPass } from "../Pages/Login";
import Dashboard from "../Pages/Dashboard";
import Deposit from "../Pages/Deposit";
import Deposit1 from "../Pages/Deposit1";
import Deposit2 from "../Pages/Deposit2";
import Withdraw from "../Pages/Withdraw";
import MyInvestments, { MyWithdrawals } from "../Pages/MyInvestments";
import Affiliate from "../Pages/Affiliate";
import Settings from "../Pages/Settings";
import Home from "../Pages/Homepage/Home";
const Routing = () => {
  const [balance, setBalance] = React.useState(0);
  const [balanceArray, setBalanceArray] = React.useState([])


  let token = Cookies.get("token");
  // if (!token) {
  //   window.location.href = "/";
  // }
  let clientid = process.env.REACT_APP_CLIENT_ID;


  const getUserBalance = async () => {
    // alert("hello")
    try {
      let api = "https://yield-jet.sentrynode.io/dashboard";
      let { data } = await axios.get(api, {
        headers: {
          "Content-Type": "application/json",
          clientid: clientid,
          "x-access-token": token,
        },
      });
      setBalanceArray(data.balances);
      localStorage.setItem("username", data.data.username);
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("firstName", data.data.firstName);
      localStorage.setItem("lastName", data.data.lastName);

    } catch (e) {
    }
  };
  React.useEffect(() => {
    getUserBalance();
  }, []);
  const [name, setName] = React.useState('')
  return (
    <BrowserRouter>

      <Routes>
        <Route
          path="/reset/password"
          element={
            <Public>
              <ResetPass />
            </Public>
          }
        />
      </Routes>

      <Routes>
        {process.env.REACT_APP_HOME == "home1" ? <Route
          path="/"
          element={
            <Public>
              <Home />
            </Public>
          }
        /> :

          <Route
            path="/"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
        }
      </Routes>
      <Routes>
        <Route
          path="/login/:id"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route
          path="/login/"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route
          path="/register"
          element={
            <Public>
              <Login />
            </Public>
          }
        />


        <Route path="dashboard">
          <Route
            path="home"
            element={
              <Private>
                <Dashboard setBalanceArray={setBalanceArray} setBalance={setBalance} setName={setName} />
              </Private>
            }
          />
          <Route
            path="deposit1"
            element={
              <Private>
                <Deposit />
              </Private>
            }
          />
          <Route
            path="deposit"
            element={
              <Private>
                <Deposit1 balanceArray={balanceArray} />
              </Private>
            }
          />
          <Route
            path="deposit2"
            element={
              <Private>
                <Deposit2 />
              </Private>
            }
          />
          <Route
            path="withdraw"
            element={
              <Private>
                <Withdraw balanceArray={balanceArray} setBalance={setBalance} balance={balance} />
              </Private>
            }
          />
          <Route
            path="myinvestments"
            element={
              <Private>
                <MyInvestments />
              </Private>
            }
          />

          <Route
            path="mywithdrawals"
            element={
              <Private>
                <MyWithdrawals />
              </Private>
            }
          />
          <Route
            path="affiliate"
            element={
              <Private>
                <Affiliate />
              </Private>
            }
          />
          <Route
            path="setting"
            element={
              <Private>
                <Settings />
              </Private>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
